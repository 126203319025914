  <div class="table-main-container d-flex flex-column  gap-3" *transloco="let t">
    <div class="table-actions d-flex flex-column flex-lg-row justify-content-between gap-2">
      <app-search-filter [(filterConfig)]="filters" [filters]="objectFilter" (onChange)="onFilterChange()"
        [(columns)]="columns">
      </app-search-filter>

      <app-to-select [isAnySelected]="isAnySelected" [isAllSelected]="isAllSelected" [totalSelected]="totalSelected"
        (onSelectAll)="onSelectAll($event)" (onDeselectAll)="onDeselectAll()" [filters]="filters"
        [objectFilter]="objectFilter" [columns]="columns">
      </app-to-select>
    </div>
    <div class="table-container">
      <table class="table sticky-header sticky-actions">
        <caption class="opacity-0">{{t('txt_list_of_data')}}</caption>
        <tr>
          <th [hidden]="checkVisible('email1')">
            <div [ngClass]="{'byOrder': checkSort('email1',undefined)}">
              <div (click)="sort('email1')">Correo electrónico</div>
              <app-material-icon *ngIf="checkSort('email1',true)">arrow_downward</app-material-icon>
              <app-material-icon *ngIf="checkSort('email1',false)">arrow_upward</app-material-icon>
            </div>
          </th>
          <th [hidden]="checkVisible('name')">
            <div [ngClass]="{'byOrder': checkSort('name',undefined)}">
              <div (click)="sort('name')">Nombre</div>
              <app-material-icon *ngIf="checkSort('name',true)">arrow_downward</app-material-icon>
              <app-material-icon *ngIf="checkSort('name',false)">arrow_upward</app-material-icon>
            </div>
          </th>
          <th [hidden]="checkVisible('role')">
            Rol
          </th>
          <th [hidden]="checkVisible('tpStatus')">
            Estado
          </th>
          <th [hidden]="checkVisible('actions')">Acciones</th>
        </tr>
        <tr *ngIf="!(simpleList.length>0)" class="p-1 ">
          <td colspan="6">
            <app-empty-panel customPadding="1rem"></app-empty-panel>
          </td>
        </tr>

        <ng-container *ngFor="let user of simpleList">
          <tr [class.active-row]="isAnySelected" [class.selected-row]="user.selected">
            <td [hidden]="checkVisible('email1')" class="email">
              <div class="d-flex align-items-center gap-2 text-primary-300 fw-semibold">
                <input class="form-check-input" type="checkbox" [(ngModel)]="user.selected">
                <div role="button" class="d-flex align-items-center gap-2 text-primary-300 fw-semibold">
                  <app-material-icon>mail</app-material-icon>
                  {{user.entity.email1}}
                </div>
              </div>
            </td>
            <td [hidden]="checkVisible('name')">{{user.entity.name }}</td>
            <td [hidden]="checkVisible('role')">
              {{t('txt_no_information')}}
            </td>
            <td [hidden]="checkVisible('tpStatus')">
              Status
            </td>
            <td [hidden]="checkVisible('actions')" class="d-flex justify-content-end">
              <app-action [editRoute]="[currentEditUrl, user.entity.hashId]">
              </app-action>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
    <app-pagination [(paginationConfig)]="this.filters" [currentCollectionSize]="this.simpleList.length"
      (onChange)="onFilterChange()">
    </app-pagination>
  </div>
