/**
 * Header Enum
 *
 * Type of headers that can be use
 * Utilized in {@link HeaderComponent}
 */
export enum HeaderEnum {
  /**
   * View - this includes edit and delete buttons
  */
  VIEW,
  /**
   * List - this includes create button
   */
  LIST,
  /**
   * Save - this includes cancel and save buttons
   */
  SAVE,
  /**
   * Save - this includes return button
   */
  VIEWDELETE,
  /**
   * Open - this includes open button
   */
  OPEN,
  /**
   * Close - this includes close button
   */
  CLOSE,
  /**
   * VIEW_OPEN - this includes edit, delete and OPEN buttons
  */
  VIEW_OPEN,
  /**
   * VIEW_CLOSE - this includes edit, delete and CLOSE buttons
   */
  VIEW_CLOSE,
  /**
  * don't show any button
  */
  NONE
}