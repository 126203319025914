import { Injectable } from '@angular/core';
import { Amplify, Auth } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';



export interface IUser {
  email: string;
  password: string;
  showPassword: boolean;
  code: string;
  username: string;
  challengeName: string;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService {

  private authenticationSubject: BehaviorSubject<any>;
  private user: any;

  constructor() {
    Amplify.configure({
      Auth: environment.cognito
    });
    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }
  //async signIn
  public signIn(user: IUser): Promise<any> {
    return Auth.signIn(user.email, user.password)
    .then((user) => {
      this.user = user;
      if(user.challengeName === 'MFA_SETUP'){
       this.createCode(user);
      }
      this.authenticationSubject.next(true);
      return user;
    }).catch((error) => {
      console.log(error);
      throw error;
    }
    );
  }


  public createNewPassword(password: string): Promise<any> {
    return Auth.completeNewPassword(this.user, password, { name: this.user.name })
  }

  public createCode (user: IUser): Promise<any> {
    return Auth.setupTOTP(user).then((code) => {
      localStorage.setItem('code', JSON.stringify(code));
    });
  }

  public confirmSignIn(user: any, code: string): Promise<any> {
    return Auth.confirmSignIn(this.user, code.toString(), 'SOFTWARE_TOKEN_MFA').then(() => {
      Auth.setupTOTP(this.user);
      this.authenticationSubject.next(true);
    }
    );
  }

  public confirmTokenTOTP(code: string): Promise<any> {
    return Auth.verifyTotpToken(this.user, code.toString()).then(() => {
      this.authenticationSubject.next(true);
    }
    );
  }

  public signOut(): Promise<any> {
    return Auth.signOut()
    .then(() => {
      this.authenticationSubject.next(false);
    });
  }

  public isAuthenticated(): Promise<boolean> {
    if (this.authenticationSubject.value) {
      return Promise.resolve(true);
    } else {
      return this.getUser()
      .then((user: any) => {
        if (user) {
          return true;
        } else {
          return false;
        }
      }).catch(() => {
        return false;
      });
    }
  }

  public forgotPassword (user: IUser): Promise<any> {
    return Auth.forgotPassword(user.email).then((response) => {
    })
  }

  public resendForgotPassword (email: string): Promise<any> {
    return Auth.forgotPassword(email);
  }

  public confirmForgotPassword (user: string, code:string, password:string): Promise<any> {
    return Auth.forgotPasswordSubmit(user, code, password);
  }

  public getAccessToken(): Promise<string> {
    return Auth.currentSession()
    .then((session: any) => {
      return Promise.resolve(session.getAccessToken().getJwtToken());
    });
  }


  public getUser(): Promise<any> {
    return Auth.currentAuthenticatedUser();
  }

  public updateUser(user: IUser): Promise<any> {
    return Auth.currentUserPoolUser()
    .then((cognitoUser: any) => {
      return Auth.updateUserAttributes(cognitoUser, user);
    });
  }

}
