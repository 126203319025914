import { ColumnConfig, FilterSearch, ObjectFilter } from "src/app/app-commons/components/search-filter/filter-util"
import { FilterEnum } from "src/global/filter-enum"
import { TypologiesEnum } from "src/global/typologies-enum"

export const TableListFilter: ObjectFilter = {
  id: 'table-list-component-filter',
  filters: [
    {
      id: 'name',
      name: 'Nombre',
      type: FilterEnum.STRING
    },
    {
      id: 'email1',
      name: 'Correo electrónico',
      type: FilterEnum.STRING
    },
    {
      id: 'tpStatus',
      name: 'Estado',
      values: [
        { value: `${TypologiesEnum.ACTIVE}`, description: 'Activo' },
        { value: `${TypologiesEnum.INACTIVE}`, description: 'Inactivo' }
      ],
      type: FilterEnum.LIST
    }
  ],
  currentFilters: []
}

export const TableFilter: FilterSearch = {
  filter_id: 'table-list-component',
  max: 10,
  page: 1,
  collectionSize: 0,
  asc: true,
  columnOrder: 'email1',
  externalUser: false,
  externalProviderUser: undefined,
  externalClientUser: undefined,
  providerId: undefined,
  clientId: undefined
}

export const TableColumns: ColumnConfig = {
  id: 'table-list-component-columns',
  config: {
    email1: { name: 'Correo electrónico', default: true },
    name: { name: 'Nombre', default: true, dto: 'admPerson.fullName' },
    role: { name: 'Rol', selected: true },
    tpStatus: { name: 'Estado', selected: true },
    actions: { name: 'Acciones', default: true }
  }
}
