/**
 * Btn Enum
 *
 * Type of buttons that can be use
 * Utilized in {@link ConfirmComponent}
 */
export enum BtnEnum {
    /**
     * delete button, red
    */
    DELETE,
    /**
     * confirm button, blue
     */
    CONFIRM
  }