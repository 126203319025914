import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TabConfig } from 'src/app/app-commons/components/header/tabs-configs';
import { FooterEnum } from 'src/global/footer-enum';

/**
 * Component that handle the different button layouts located in the footer
 * such as create, view and edit with or without a wizard
 *
 * Example for create and edit view
 * @Example
 * ```html
 *  <app-footer
 *    [type]="this.hashId ? footerEnum.EDIT : footerEnum.CREATE"
 *    cancelRoute="/security/roles"
 *    (saveAction)="save()"
 *    [permission]="permissionsEnum.ROLES"
 *  >
 *  </app-footer>
 * ```
 *
 * @Example
 * Example for create and edit view with a wizard
 * ```html
 *  <app-footer
 *    [type]="userHashId ? footerEnum.EDIT_WITH_WIZARD : footerEnum.CREATE_WITH_WIZARD"
 *    cancelRoute="/security/users/"
 *    [tabs]="tabs"
 *    [selectedTab]="tabSelected"
 *    (saveAction)="save()"
 *    (navigateAction)="navigate($event)"
 *    [permission]="permissionEnum.USERS"
 *  >
 *  </app-footer>
 * ```
 *
 * @Example
 * Example for display view
 * ```html
 *  <app-footer
 *    [type]="footerEnum.VIEW_WITH_WIZARD"
 *    (navigateAction)="navigate($event)"
 *    [tabs]="tabs"
 *    [selectedTab]="tabSelected"
 *    >
 *  </app-footer>
 * ```
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  footerEnum = FooterEnum;

  @Input() type: FooterEnum = FooterEnum.NONE;
  @Input() cancelRoute: any[] | string | null | undefined = '../';
  @Input() txtCancelRoute: string = 'cancel';
  @Input() selectedTab: number = 0;
  @Input() tabs: TabConfig[] = [];
  @Input() needsCancel: boolean = false;
  /**
   * When true, save as draft button is visible.
   * Type: boolean
   * Default value is false.
   */
  @Input() needsSaveAsDraft: boolean = false;
  @Input() txtExtraButton: string = '';
  @Input() txtReportButton: string = 'txt_print_report'; //Transloco key value
  /**
   * Show or not the delete button
   */
  @Input() showDeleteAction: boolean = true;
  /**
   * Show or not the extra button (used in REPORTS)
   */
  @Input() showExtraButton: boolean = false;

  @Input() edit: boolean = false;

  @Output() saveAction = new EventEmitter<void>();
  @Output() saveAsDraftAction = new EventEmitter<void>();
  @Output() deleteAction = new EventEmitter<void>();
  @Output() continueAction = new EventEmitter<void>();
  @Output() navigateAction = new EventEmitter<boolean>();
  @Output() cancelAction = new EventEmitter<void>();
  @Output() generateAction = new EventEmitter<void>();
  @Output() reportAction = new EventEmitter<void>();
  @Output() extraAction = new EventEmitter<void>();
  @Output() saveDraftAction = new EventEmitter<void>();
  

  save() {
    this.saveAction.emit();
  }

  saveDraft() {
    this.saveAction.emit();
  }

  saveAsDraft() {
    this.saveAsDraftAction.emit();
  }

  delete() {
    this.deleteAction.emit();
  }

  cancel() {
    this.cancelAction.emit();
  }

  navigate(asc: boolean) {
    this.navigateAction.emit(asc);
  }

  generate() {
    this.generateAction.emit();
  }

  report() {
    this.reportAction.emit();
  }

  extra() {
    this.extraAction.emit();
  }

  get wizardBack(): boolean {
    return this.selectedTab > 0 && this.navigateAction.observed && this.tabs[this.selectedTab].back;
  }

  get wizardContinue(): boolean {
    return this.selectedTab + 1 < this.tabs.length && this.navigateAction.length !== 0 && this.tabs[this.selectedTab].btnContinue;
  }

  get wizardSave(): boolean {
    return this.selectedTab + 1 === this.tabs.length
      && this.saveAction.length !== 0 && this.tabs[this.selectedTab].btnCreate;
  }

  get showDeleteButton(): boolean {
    return this.deleteAction.observed
      && this.showDeleteAction;
  }

  get showSaveButton(): boolean {
    return this.saveAction.observed;
  }

}
