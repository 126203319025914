import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingControlService } from '../app-commons/components/loading-indicator/loading-control.service';

@Injectable()
export class LoadingUiInterceptor implements HttpInterceptor {

  constructor(
    private loadingControlService: LoadingControlService
  ) { }

  private countRequest = 0;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.countRequest++;
    this.loadingControlService.showLoadingIndicator();
    
    let timeIndicator = localStorage.getItem('delayTime')||0;
  
    return next.handle(request).pipe(
      finalize(() => {
        this.countRequest--;
        if (!this.countRequest) {
          setTimeout(() => {
            localStorage.removeItem('delayTime');
            this.loadingControlService.hideLoadingIndicator();  
          }, +timeIndicator);
          
        }
      })
    );
  }
}
