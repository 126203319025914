import { Injectable } from '@angular/core';
import { NgbCalendar, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { ColumnConfig, DateFilter, FilterSearch, ObjectFilter } from "src/app/app-commons/components/search-filter/filter-util";
import { FilterEnum } from "src/global/filter-enum";

/**
 * Service used to recover the filters from the local storage. Use example:
 *
 * @Example
 * ```typescript
 * constructor(private filterService: FilterService) {
 * }
 *
 * ngOnInit(): void {
 *  // getting filters from localstorage
 *  this.filterService.getFilters(this.myObjectFilter, this.myFilters);
 * }
 *
 * ```
 *
 */
@Injectable({
  providedIn: 'root'
})
export class FilterService {

  constructor(
    private calendar: NgbCalendar,
    private formatter: NgbDateParserFormatter
  ) {
  }

  /**
   * Method to recover filters and columns config from localstorage
   * @param objectFilter
   * @param filters
   * @param column
   */
  getFiltersAndColumnsConfig(
    objectFilter: ObjectFilter,
    filters: FilterSearch,
    column: ColumnConfig
  ) {
    this.getFilters(objectFilter, filters);
    this.getColumnsConfig(column);
  }

  /**
   * Method to recover filters from localstorage
   * @param {ObjectFilter} objectFilter
   * @param {FilterSearch} filters
   */
  getFilters(
    objectFilter: ObjectFilter,
    filters: FilterSearch
  ) {
    try {
      this.getFiltersForComponent(objectFilter, filters);
    } catch (error) {
    }
  }

  /**
   * Method to recover columns config from localstorage
   * @param column
   */
  getColumnsConfig(column: ColumnConfig) {
    try {
      this.getColumnsConfigForComponent(column);
    } catch (error) {
    }
  }

  private getFiltersForComponent(
    objectFilter: ObjectFilter,
    filters: FilterSearch
  ) {
    const json = localStorage.getItem(filters.filter_id);
    if (!json) {
      return;
    }

    const tmp = JSON.parse(json) as FilterSearch;
    if (typeof tmp !== 'object') {
      return;
    }

    // pagination
    filters.max = tmp.max;
    filters.page = tmp.page;
    filters.collectionSize = tmp.collectionSize;

    // check objectFilter
    const jsonFilter = localStorage.getItem(objectFilter.id);
    if (!jsonFilter) {
      return;
    }

    const tmpObjectFilter = JSON.parse(jsonFilter) as ObjectFilter;
    if (typeof tmpObjectFilter !== 'object') {
      return;
    }

    if (objectFilter.filters.length !== tmpObjectFilter.filters.length) {
      return;
    }

    objectFilter.currentFilters = [];
    objectFilter.filters.forEach((filter, index) => {
      const tmpFilter = tmpObjectFilter.filters[index];
      if (tmpFilter.type != filter.type || tmpFilter.id != filter.id || tmpFilter.name != filter.name) {
        return;
      }

      if (tmpFilter.selected) {
        // add the configuration from the localstorage
        Object.assign(filter, tmpObjectFilter.filters[index]);
        objectFilter.currentFilters.push(filter);
        this.onProcessFilter(objectFilter, filters, objectFilter.currentFilters.length - 1);
      }
    });
  }

  private getColumnsConfigForComponent(column: ColumnConfig) {
    const json = localStorage.getItem(column.id);
    if (!json) {
      return;
    }

    const tmpColumns = JSON.parse(json) as ColumnConfig;
    if (typeof tmpColumns !== 'object') {
      return;
    }

    if (column.id != tmpColumns.id) {
      return;
    }

    for (const key in tmpColumns.config) {
      if (!column.config[key]) {
        return;
      }

      // default and name from original configuration
      tmpColumns.config[key].default = column.config[key].default;
      tmpColumns.config[key].name = column.config[key].name;

      // add the configuration from localstorage and set visible to true
      Object.assign(column.config[key], tmpColumns.config[key]);
      column.config[key].display = true;
    }
  }

  onProcessFilter(
    objectFilter: ObjectFilter,
    filterValues: { [property: string]: any },
    index: number,
  ) {
    const filter = objectFilter.currentFilters[index];
    let isValid = false;
    if (!filter) {
      return;
    }

    switch (filter.type) {
      case FilterEnum.STRING:
        if (filter.value && filter.value.trim()) {
          isValid = true;
          filterValues[filter.id] = filter.value.trim();
        } else {
          filter.value = undefined;
        }
        break;
      case FilterEnum.DATE:
        if (filter.value && this.calendar.isValid(filter.value)) {
          isValid = true;
          filterValues[filter.id] = this.formatter.format(filter.value);
        } else {
          filter.value = undefined;
        }
        break;
      case FilterEnum.NUMBER:
        if (filter.value !== undefined && filter.value != null && !Number.isNaN(filter.value)) {
          filter.value = Number(filter.value);
          isValid = true;
          filterValues[filter.id] = filter.value;
        } else {
          filter.value = undefined;
        }
        break;
      case FilterEnum.LIST:
        if (filter.selectedValue) {
          isValid = true;
          filterValues[filter.id] = filter.selectedValue.value;
        }
        break;
      case FilterEnum.MULTIPLE_LIST:
        if (filter.selectedValues?.length) {
          isValid = true;
          filterValues[filter.id] = filter.selectedValues
            .map(option => option.value)
            .reduce((prev, current) => prev.concat(',').concat(current));
        }
        break;
    }

    if (!isValid) {
      filter.selected = false;
      objectFilter.currentFilters.splice(index, 1);
      filterValues[filter.id] = undefined;
    }
  }

  validateDateInput(filter: DateFilter): string {
    const date = filter.value;
    const value = this.formatter.format(date ?? null);
    if (this.formatter.parse(value)) {
      return value;
    }
    return 'Formato no válido';
  }

  validateMultipleInput(values: { value: string, description: string }[] | undefined) {
    if (!values?.length) {
      return '';
    }

    const str = values
      .map(value => value.description)
      .reduce((prev, current) => prev.concat(', ').concat(current));

    return ': '.concat(str);
  }
}
