/**
 * Type of footer that can be used in {@link FooterComponent}
 */
export enum FooterEnum {
    NONE,
    CREATE,
    CREATE_PRODUCT_TRANSACTION,
    CREATE_WITH_WIZARD,
    VIEW_WITH_WIZARD,
    EDIT,
    EDIT_WITH_WIZARD,
    GENERATE,
    REPORT,
    SAVE,
    VIEW
}