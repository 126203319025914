import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { FooterEnum } from 'src/global/footer-enum';
import { HeaderEnum } from 'src/global/header-enum';

interface CombinedData {
  asc: boolean;
  tabSelected: number;
}

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent {

  @Input() title = '';
  @Input() createRoute = 'add';
  @Input() tabs!: any;
  @Input() showNumber: boolean = false;
  @Input() showTabCheck: boolean = false;
  @Input() showName: boolean = true;
  @Input() headerEnum = HeaderEnum.LIST ;
  @Input() edit : boolean = false;
  @Input() validateCompleteTab: boolean = false;
  @Input() loadComponent: boolean = false;
  @Input() changingValue!: Subject<CombinedData>;
  @Input() noNavigation: boolean = false;
  @Output() tabSelected = new EventEmitter<number>()
  @Output() addRoute = new EventEmitter<any>()
  @Output() addAction = new EventEmitter<any>()
  route = '';
  permission:number= FooterEnum.CREATE
  sideNavStatus: boolean = false;




  constructor(
    private activateRoute: ActivatedRoute,
    private router: Router
  ) { }

  changeView(tabSelected: number) {
    this.tabSelected.emit(tabSelected)
  }
  add() {
    this.addRoute.emit()
  }
  createRouteAction() {
    if(this.addAction)
      this.addAction.emit();
  }

}
