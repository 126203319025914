<div *ngIf="type != footerEnum.NONE" class="footer border-top pt-2 pb-1 mb-1 footer-bottom">
    <ng-container *transloco="let t">
        <!-- CREATE -->
        <div
        *ngIf="type == footerEnum.CREATE"
        class="d-flex gap-2 justify-content-end"
        >
        <button [routerLink]="cancelRoute" class="btn btn-outline-primary px-4 cancelButton">
            {{ t(txtCancelRoute) }}
        </button>
        <button
            *ngIf="needsSaveAsDraft"
            (click)="saveAsDraft()"
            class="btn btn-primary"
        >
            {{ t("save_draft") }}

        </button>
        <button
        *ngIf="showSaveButton"
            (click)="save()"
            class="btn btn-primary px-5"
        >
            {{ edit ? t("edit") : t("create") }}
        </button>
        </div>

        <div
        *ngIf="type == footerEnum.GENERATE"
        class="d-flex gap-2 justify-content-end"
        >
        <button [routerLink]="cancelRoute" class="btn btn-outline-primary px-4">
            {{ t("cancel") }}
        </button>

        <button
            *ngIf="generateAction.observers.length"
            (click)="generate()"
            class="btn btn-primary px-5"
        >
            {{ t("generate") }}
        </button>
        </div>

        <!-- CREATE WITH WIZARD -->
    <div
        *ngIf="type == footerEnum.CREATE_WITH_WIZARD"
        class="d-flex gap-2 justify-content-between"
        >
        <div *ngIf="!needsCancel  && !(selectedTab == 0) && tabs[selectedTab].btnCancel"  >
            <button [routerLink]="cancelRoute" class="btn btn-outline-primary px-4">
            {{ t("cancel") }}
            </button>
        </div>
        <div *ngIf="needsCancel  && !(selectedTab == 0) && tabs[selectedTab].btnCancel">
            <button (click)="cancel()" class="btn btn-outline-primary px-4">
            {{ t("cancel") }}
            </button>
        </div>

        <div *ngIf="!needsCancel  && (selectedTab == 0)">
        </div>
        <div *ngIf="needsCancel  && (selectedTab == 0)">
        </div>

        <div class="d-flex gap-2 justify-content-end">
            <button
            *ngIf="wizardBack"
            class="btn btn-outline-primary px-4"
            (click)="navigate(false)"
            >
            {{ t("txt_back") }}
            </button>
            <button
            *ngIf="wizardContinue"
            class="btn btn-primary px-4"
            (click)="navigate(true)"
            >
            {{ t("txt_continue") }}
            </button>

        </div>
    </div>
<div *ngIf="type == footerEnum.CREATE_WITH_WIZARD"
class="d-flex gap-2 justify-content-end">
  <button
  *ngIf="wizardSave"
  (click)="save()"
  class="btn btn-primary px-5"
  >
  {{ t(tabs[tabs.length-1].txtName) }}
  </button>
</div>
        <!-- VIEW WITH WIZARD -->
        <div
        *ngIf="type == footerEnum.VIEW_WITH_WIZARD"
        class="d-flex gap-2 justify-content-end"
        >
        <button
            *ngIf="wizardBack"
            class="btn btn-outline-primary px-4"
            (click)="navigate(false)"
        >
            {{ t("txt_back") }}
        </button>
        <button
            *ngIf="wizardContinue"
            class="btn btn-primary px-4"
            (click)="navigate(true)"
        >
            {{ t("txt_continue") }}
        </button>
        </div>

        <!-- EDIT -->
        <div *ngIf="type == footerEnum.EDIT" class="d-flex justify-content-between">
        <div>
            <button
            *ngIf="showDeleteButton"
            class="btn btn-danger px-4"
            (click)="delete()"
            >
            {{ t("delete") }}
            </button>
        </div>
        <div class="d-flex gap-2 justify-content-end">
            <button [routerLink]="cancelRoute" class="btn btn-outline-primary px-4">
            {{ t("cancel") }}
            </button>
            <button
            *ngIf="needsSaveAsDraft"
            (click)="saveAsDraft()"
            class="btn btn-primary"
            >
            {{ t("save_draft") }}
            </button>
            <button
            *ngIf="showSaveButton"
            (click)="save()"
            class="btn btn-primary px-4"
            >
            {{ t("save") }}
            </button>
        </div>
        </div>

        <!-- EDIT WITH WIZARD -->
        <div
            *ngIf="type == footerEnum.EDIT_WITH_WIZARD"
            class="d-flex justify-content-between"
        >
        <div class="d-flex gap-2 justify-content-start">
            <button
                *ngIf="showDeleteButton"
                class="btn btn-danger px-4"
                (click)="delete()"
                >
                {{ t("delete") }}
            </button>
            <button [routerLink]="cancelRoute" class="btn btn-outline-primary px-4">
            {{ t("cancel") }}
            </button>
        </div>
            <div class="d-flex gap-2 justify-content-end">
            <button
            *ngIf="wizardBack"
            class="btn btn-outline-primary px-4"
            (click)="navigate(false)"
            >
            {{ t("txt_back") }}
            </button>
            <button
                *ngIf="wizardContinue"
                class="btn btn-primary px-4"
                (click)="navigate(true)"
                >
                {{ t("txt_continue") }}
            </button>
            <button
            *ngIf="wizardSave"
            (click)="save()"
            class="btn btn-primary px-5"
            >
            {{ t("save") }}
            </button>
        </div>
        </div>

        <!-- REPORT -->
        <div
        *ngIf="type == footerEnum.REPORT"
        class="d-flex justify-content-between"
        >
        <div class="d-flex gap-2 justify-content-start">
            <button [routerLink]="cancelRoute" class="btn btn-outline-primary px-4">
            {{ t("cancel") }}
            </button>
        </div>
        <div class="d-flex gap-2 justify-content-end">
            <button
            *ngIf="showExtraButton"
            class="btn btn-outline-primary px-4"
            (click)="extra()"
            >
            {{ t(txtExtraButton) }}
            </button>
            <button
            (click)="report()"
            class="btn btn-primary px-5"
            >
            {{ t(txtReportButton) }}
            </button>
        </div>
        </div>
        <!-- CREATE PRODUCT TRANSACTION-->
        <div
        *ngIf="type == footerEnum.CREATE_PRODUCT_TRANSACTION"
        class="d-flex gap-2 justify-content-end"
        >
        <button [routerLink]="cancelRoute" class="btn btn-outline-primary px-4">
            {{ t("cancel") }}
        </button>

        <button
            *ngIf="saveDraftAction.observers.length"
            (click)="saveDraft()"
            class="btn btn-primary px-5"
        >
            {{ t("save_draft") }}
        </button>

        <button
            *ngIf="saveAction.observers.length"
            (click)="save()"
            class="btn btn-primary px-5"
        >
            {{ t("create") }}
        </button>
        </div>

        <!-- SAVE -->
        <div
        *ngIf="type == footerEnum.SAVE"
        class="d-flex gap-2 justify-content-between"
        >
        <button [routerLink]="cancelRoute" class="btn btn-outline-primary px-4">
            {{ t("cancel") }}
        </button>
        <button (click)="save()" class="btn btn-primary px-5">
            {{ t("save") }}
        </button>
        </div>
    </ng-container>
</div>
