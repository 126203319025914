import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { ToasterEnum } from 'src/global/toaster-enum';

@Component({
  selector: 'app-file-drag-and-drop',
  templateUrl: './file-drag-and-drop.component.html',
  styleUrls: ['./file-drag-and-drop.component.scss']
})

export class FileDragAndDropComponent {


  @Output() file = new EventEmitter<File>();

  @Input() allowedExtensions: string[] = ['.zip'];
  @Input() multipleFiles = false;
  @Input() textLineOne = 'txt_upload_file';
  @Input() textLineTwo = 'txt_data_load';
  @Input() showTemplate = false;
  @Output() download = new EventEmitter<any>();

  title = 'txt_load_file';
  fileName: string | null = null;
  hasError: boolean = false;
  timeError = 1000;
  readonly FILE_SIZE = 10 * 1024 * 1024;

  constructor(
    private toaster: ToasterService
  ) { }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file && this.isValidExtension(file) &&  file.size < this.FILE_SIZE) {
        this.fileName = file.name;
        this.file.emit(file);
    }else{
      this.handlerError('msg_error_invalid_file_extension');
    }

  }

  onDragOver(event: any): void {
    event.preventDefault();
    event.stopPropagation();

    event.target.classList.add('dragging');
  }

  onDragLeave(event: any): void {
    event.preventDefault();
    event.stopPropagation();

    event.target.classList.remove('dragging');
  }

  onDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files || event.target.files;
    if (this.multipleFiles || files.length === 1) {
      for (const file of files) {
        if (this.isValidExtension(file)) {
          this.fileName = file.name;
          this.file.emit(file);
        } else {
          this.handlerError('msg_error_invalid_file_extension');
        }
      }
    }else if(files.size > this.FILE_SIZE){
      this.handlerError('msg_error_file_size');
    }else {
      this.handlerError('msg_error_multiple_file_upload_not_allowed');
    }
  }

  private handlerError(message : string){
    this.toaster.show({
      message: message,
      header: 'Error ',
      type: ToasterEnum.ERROR,
    });
    this.hasError = true;
    setTimeout(() => {
      this.hasError = false;
    },this.timeError );
  }
  private isValidExtension(file: File): boolean {
    const extension = '.' + file.name.split('.').pop();
    return this.allowedExtensions.includes(extension);
  }

  onButtonClick(): void {
    document.getElementById('fileInput')?.click();
  }
  downloadTemplate(){
    this.download.emit();
  }
}
