import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input() sideNavStatus: boolean = false;
  formattedDate: string = "";
  currentTime: string = "";
  dropdownStatus: boolean = false;

  logRoute = '/administration/log';
  packageRoute = '/administration/package';
  syncRoute = '/administration/sync';

  typeRoute = '/setting/type';
  subtypeRoute = '/setting/subtype';
  screenRoute = '/setting/screen';
  classRoute = '/setting/class';

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  toggleDropdown() {
    this.dropdownStatus = !this.dropdownStatus;
  }

  refresh() {
    if(this.router.url == this.syncRoute) {
      window.location.reload();
    }
  }

}
