<div class="main-container" *transloco="let t">
  <div class="body-container bg-white">
    <app-navbar (sideNavToggled)="sideNavStatus = $event"></app-navbar>
    <main>
      <app-sidebar [sideNavStatus]="sideNavStatus" [ngClass]="{ 'app-sidebar-open': sideNavStatus }"></app-sidebar>
    </main>
    <div class="extender" [ngClass]="{ 'app-sidebar-open-margin': sideNavStatus }">
      <app-header [title]="title" [type]="headerEnum"
        [createRoute]="createRoute"
        [createText]="'txt_add'"
        [tabs]="tabs ? tabs : null"
        [showName]="showName"
        [showTabNumbers]="showNumber"
        [validateCompleteTab]="validateCompleteTab"
        (tabSelected)="changeView($event)"
        [changing]="changingValue"
        [showTabCheck]="showTabCheck"
        [loadComponent]="loadComponent"
        (createAction)="createRouteAction()"
        [noNavigation]="noNavigation"
        [edit]="edit"
        >

      </app-header>
      <ng-content class="fix-position">

      </ng-content>
    </div>
  </div>
</div>
