<nav class="navbar navbar-expand-lg  no-print">
  <div class="container-fluid">

    <div class="d-flex align-items-center justify-content-center">
      <app-material-icon class="menu mx-3" (click)="SideNavToggle()">
        menu
      </app-material-icon>

      <a class="navbar-brand" [routerLink]="['/administration/log']">
        <img src="./assets/logo/Logo/Full.png" alt width="74" height="38" class="d-inline-block align-text-top">
      </a>
      <div class="version text-center text-gray-500 mt-4">v{{version}}</div>
    </div>

    <div class="user-data text-white d-flex gap-2 align-items-center order-2 order-lg-3">
      <div ngbDropdown>
        <button type="button" class="btn text-start p-0" ngbDropdownToggle>
          <div class="user-info text-white   align-items-center d-flex gap-1">
            <div class="d-flex flex-column d-none mx-3 d-lg-block">
              <p class="user-name m-0 fw-semibold fs-14">{{email}}</p>
              <p class="user-role text-end m-0 fs-12">Administrador</p>
            </div>
            <div class="avatar fw-semibold h6 m-0">
              {{usernameInitials}}
            </div>
          </div>
        </button>
        <div ngbDropdownMenu class="end-0">
          <button *ngIf="false" ngbDropdownItem>
            <app-material-icon class="d-none d-lg-block">
              person
            </app-material-icon>
            <p>Mi perfil</p>
          </button>
          <button ngbDropdownItem  (click)="signOut()">
            <app-material-icon class="d-none d-lg-block">
              logout
            </app-material-icon>
            <p>Cerrar sesión</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>
