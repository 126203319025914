/**
 * Permissions Types
 */

export enum PermissionTypeEnum {
    READ = "read",
    CREATE = "create",
    UPDATE = "update",
    DELETE = "delete",
    EXPORT = "export",
    MENU = "menu",
    NONE = "none"
}

/**
 * Permissions Enum
 */
export const PermissionsEnum = {
    /**
     * Default
     */
    NULL: 0,
    /**
     * Default to skip permission validation
     */
    NONE: -1,
    /**
     *  Module permission type
     */
    HOME: 1,
    /**
     *  Module permission type
     */
}
