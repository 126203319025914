import { ColumnConfig, FilterSearch, ObjectFilter } from "src/app/app-commons/components/search-filter/filter-util";
import { HeaderEnum } from "src/global/header-enum";
import { PermissionsEnum } from "src/global/permission-utils";
import { TypologiesEnum } from "src/global/typologies-enum";

export abstract class SimpleList {
  abstract objectFilter: ObjectFilter;
  abstract filters: FilterSearch;
  abstract columns: ColumnConfig;
  permissionsEnum = PermissionsEnum;
  typologyEnum = TypologiesEnum;
  headerEnum = HeaderEnum;
  abstract simpleList: { selected: boolean, entity: any }[];

  abstract findAll(): void;

  onFilterChange() {
    this.findAll();
  }

  sort(name: string) {
    if (!this.filters) {
      return;
    }

    if (this.filters.columnOrder == name) {
      this.filters.asc = !this.filters.asc;
    } else {
      this.filters.columnOrder = name;
      this.filters.asc = true;
    }

    this.filters = JSON.parse(JSON.stringify(this.filters));
    this.findAll();
  }

  checkSort(name: string, sort: boolean | undefined) {
    if (!this.filters) {
      return false;
    }

    if (name === this.filters.columnOrder) {
      return sort === undefined ? true : (sort === this.filters.asc);
    }
    return false;
  }

  checkVisible(name: string) {
    if (!this.columns || !this.columns.config[name]) {
      return false;
    }

    return !this.columns.config[name].default && !this.columns.config[name].selected;
  }

  get isAnySelected() {
    return this.simpleList.some(item => item.selected);
  }

  get totalSelected() {
    return this.simpleList.filter(item => item.selected).length;
  }

  get isAllSelected() {
    return this.simpleList.length === this.totalSelected;
  }

  onDeselectAll() {
    this.simpleList.forEach(item => item.selected = false);
  }

  onSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.simpleList.forEach(item => item.selected = checked);
  }
}
