import { Directive, EventEmitter, HostListener, OnDestroy, Output } from "@angular/core";

@Directive({
  selector: '[appDoubleClick]'
})
export class DoubleClickDirective implements OnDestroy {

  @Output() singleClick = new EventEmitter();
  @Output() doubleClick = new EventEmitter();
  timer: any;
  stopClick!: boolean;

  @HostListener('click', ['$event']) onClick(e: any) {
    this.timer = 0;
    this.stopClick = false;
    const delay = 200;

    this.timer = setTimeout(() => {
      if (!this.stopClick) {
        this.singleClick.emit(e);
      }
    }, delay);
  }

  @HostListener('dblclick', ['$event']) onDbClick(e: any) {
    this.stopClick = true;
    clearTimeout(this.timer);
    this.doubleClick.emit(e);
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }
}
