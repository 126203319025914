<ng-template *transloco="let t" #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{t(title)}}
    </h5>
    <button class="close btn btn-outline-primary" aria-label="Close" (click)="closeBtn()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li *transloco="let t" [ngbNavItem]="1">
        <button ngbNavLink>{{t('information')}}</button>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-12 col-lg-6" *ngFor="let input of configuracionInputs">

              <ng-container [ngSwitch]="input.inputType">

                <div *ngSwitchCase="'text'">
                  <div class="mb-3">
                    <label class="form-label">
                      <span class="text-danger fw-bold me-2" *ngIf="input.required">*</span>
                      {{ t(input.key) }}
                    </label>
                    <input class="form-control" [required]="input.required"
                      [placeholder]="t(input.textPlaceholder ? input.textPlaceholder : '')"
                      [maxlength]="input.lengthType ? input.lengthType : 25" [(ngModel)]="input.value"
                      [readonly]="input.readonly" [ngClass]="{'is-invalid': isNotNullOrUndefined(input.value) && input.required}">
                    <div class="invalid-feedback">
                      {{t('msg_validation_required_field')}}
                    </div>
                  </div>
                </div>

                <div *ngSwitchCase="'number'">
                  <div class="mb-3">
                    <label class="form-label">
                      <span class="text-danger fw-bold me-2" *ngIf="input.required">*</span>
                      {{ t(input.key) }}
                    </label>
                    <input type="number" [required]="input.required" class="form-control"
                      [placeholder]="t(input.textPlaceholder ? input.textPlaceholder : '')" [(ngModel)]="input.value"
                      min="0" [readonly]="input.readonly" [ngClass]="{'is-invalid': isInvalid(input)}">
                    <div class="invalid-feedback">
                      {{ (isInvalid(input) && input.value < 0) ? t('msg_validation_number_negative_field') :
                        (input.required && isInvalid(input) ? t('msg_validation_required_field') : '' ) }} </div>
                    </div>
                  </div>

                  <div *ngSwitchCase="'date'">
                    <div class="mb-3">
                      <label class="form-label">
                        <span class="text-danger fw-bold me-2" *ngIf="input.required">*</span>
                        {{ t(input.key) }}
                      </label>
                      <input type="date" [required]="input.required" class="form-control"
                        [placeholder]="t(input.textPlaceholder ? input.textPlaceholder : '')" [(ngModel)]="input.value"
                        [readonly]="input.readonly" [ngClass]="{'is-invalid': isNotNullOrUndefined(input.value) && input.required}">
                      <div class="invalid-feedback">
                        {{t('msg_validation_required_field')}}
                      </div>
                    </div>
                  </div>

                  <div *ngSwitchCase="'textarea'">
                    <div class="mb-3">
                      <label class="form-label">
                        <span class="text-danger fw-bold me-2" *ngIf="input.required">*</span>
                        {{ t(input.key) }}
                      </label>
                      <textarea [required]="input.required"
                        [placeholder]="t(input.textPlaceholder ? input.textPlaceholder : '')"
                        [maxlength]="input.lengthType ? input.lengthType : 25" [(ngModel)]="input.value" type="text"
                        [readonly]="input.readonly" class="form-control" [id]="input.value" [name]="input.value"
                        #c7="ngModel" [ngClass]="{'is-invalid': isNotNullOrUndefined(input.value) && input.required}">
                                  </textarea>
                      <div class="invalid-feedback">
                        {{t('msg_validation_required_field')}}
                      </div>
                    </div>
                  </div>

                  <div *ngSwitchCase="'label'">
                    <div class="mb-3">
                      <label class="form-label">
                        <span class="text-danger fw-bold me-2" *ngIf="input.required">*</span>
                        {{ t(input.key) }}
                      </label>
                      <div *ngIf="!isNotNullOrUndefined(input.value); then labels; else noLabels"></div>
                      <ng-template #labels>
                        <p class="shaded-label">{{ input.value }}</p>
                      </ng-template>
                      <ng-template #noLabels>
                        <p class="shaded-label text-gray-600">{{ t("txt_no_information") }}</p>
                      </ng-template>
                    </div>
                  </div>

                  <div *ngSwitchCase="'select'">
                    <div class="mb-3">
                      <label class="form-label">
                        <span class="text-danger fw-bold me-2" *ngIf="input.required">*</span>
                        {{ t(input.key) }}
                      </label>
                      <ng-select *ngIf="input?.selectOptions && input.fieldName"
                        [items]="input.selectOptions?.items || []" [bindLabel]="input.selectOptions?.bindLabel || ''"
                        [bindValue]="input.selectOptions?.bindValue || ''" [multiple]="false"
                        [notFoundText]="t(input.selectOptions?.notFoundText || '')"
                        [searchable]="input.selectOptions?.searchable || false"
                        [clearable]="input.selectOptions?.clearable || false"
                        [placeholder]="t(input.selectOptions?.placeholder || '')"
                        [ngClass]="{'is-invalid': isSelectInvalid(input)}"
                        [readonly]="input.selectOptions?.disabled || false"
                        [(ngModel)]="selectValues[input.alias ? input.alias : input.fieldName]" [appendTo]="'body'"
                        (ngModelChange)="onSelectChange(input, $event)">
                        <ng-template ng-label-tmp let-item="item" let-index="index">
                          {{ t(item.name) }}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                          {{ t(item.name)}}
                        </ng-template>
                      </ng-select>
                      <div class="invalid-feedback">
                        {{t('msg_validation_required_field')}}
                      </div>
                    </div>
                  </div>

              </ng-container>
            </div>
          </div>

        </ng-template>
      </li>
      <li *ngIf="existObjectConfig()" [ngbNavItem]="2">
        <button *transloco="let t" ngbNavLink>{{t('txt_options_groups')}}</button>
        <ng-template *transloco="let t" ngbNavContent>
          <div class="row">
            <div [ngClass]="{'col-lg-6': input.inputType !== 'list'}" class="col-12"
              *ngFor="let input of getObjectsConfigExist()">

              <ng-container [ngSwitch]="input.inputType">

                <div *ngSwitchCase="'text'">
                  <div class="mb-3">
                    <label class="form-label">
                      <span class="text-danger fw-bold me-2" *ngIf="input.required">*</span>
                      {{ t(input.key) }}
                    </label>
                    <input class="form-control" [required]="input.required"
                      [placeholder]="t(input.textPlaceholder ? input.textPlaceholder : '')"
                      [maxlength]="input.lengthType ? input.lengthType : 25" [(ngModel)]="input.value"
                      [readonly]="input.readonly" [ngClass]="{'is-invalid': isNotNullOrUndefined(input.value) && input.required}">
                    <div class="invalid-feedback">
                      {{t('msg_validation_required_field')}}
                    </div>
                  </div>
                </div>

                <div *ngSwitchCase="'number'">
                  <div class="mb-3">
                    <label class="form-label">
                      <span class="text-danger fw-bold me-2" *ngIf="input.required">*</span>
                      {{ t(input.key) }}
                    </label>
                    <input type="number" [required]="input.required" class="form-control"
                      [placeholder]="t(input.textPlaceholder ? input.textPlaceholder : '')" [(ngModel)]="input.value"
                      min="0" [readonly]="input.readonly" [ngClass]="{'is-invalid': isInvalid(input)}">
                    <div class="invalid-feedback">
                      {{ (isInvalid(input)) ? t('msg_validation_number_negative_field') :
                      (isInvalid(input) ? t('msg_validation_required_field') : '') }}
                    </div>
                  </div>
                </div>

                <div *ngSwitchCase="'date'">
                  <div class="mb-3">
                    <label class="form-label">
                      <span class="text-danger fw-bold me-2" *ngIf="input.required">*</span>
                      {{ t(input.key) }}
                    </label>
                    <input type="date" [required]="input.required" class="form-control"
                      [placeholder]="t(input.textPlaceholder ? input.textPlaceholder : '')" [(ngModel)]="input.value"
                      [readonly]="input.readonly" [ngClass]="{'is-invalid': isNotNullOrUndefined(input.value) && input.required}">
                    <div class="invalid-feedback">
                      {{t('msg_validation_required_field')}}
                    </div>
                  </div>
                </div>

                <div *ngSwitchCase="'textarea'">
                  <div class="mb-3">
                    <label class="form-label">
                      <span class="text-danger fw-bold me-2" *ngIf="input.required">*</span>
                      {{ t(input.key) }}
                    </label>
                    <textarea [required]="input.required"
                      [placeholder]="t(input.textPlaceholder ? input.textPlaceholder : '')"
                      [maxlength]="input.lengthType ? input.lengthType : 25" [(ngModel)]="input.value" type="text"
                      [readonly]="input.readonly" class="form-control" [id]="input.value" [name]="input.value"
                      #c7="ngModel" [ngClass]="{'is-invalid': isNotNullOrUndefined(input.value) && input.required}">
                                  </textarea>
                    <div class="invalid-feedback">
                      {{t('msg_validation_required_field')}}
                    </div>
                  </div>
                </div>

                <div *ngSwitchCase="'label'">
                  <div class="mb-3">
                    <label class="form-label">
                      <span class="text-danger fw-bold me-2" *ngIf="input.required">*</span>
                      {{ t(input.key) }}
                    </label>
                    <div *ngIf="!isNotNullOrUndefined(input.value); then labels; else noLabels"></div>
                    <ng-template #labels>
                      <p class="shaded-label">{{ input.value }}</p>
                    </ng-template>
                    <ng-template #noLabels>
                      <p class="shaded-label text-gray-600">{{ t("txt_no_information") }}</p>
                    </ng-template>
                  </div>
                </div>

                <div *ngSwitchCase="'select'">
                  <div class="mb-3">
                    <label class="form-label">
                      <span class="text-danger fw-bold me-2" *ngIf="input.required">*</span>
                      {{ t(input.key) }}
                    </label>
                    <ng-select *ngIf="input?.selectOptions && input.fieldName"
                      [items]="input.selectOptions?.items || []" [bindLabel]="input.selectOptions?.bindLabel || ''"
                      [bindValue]="input.selectOptions?.bindValue || ''" [multiple]="false"
                      [notFoundText]="t(input.selectOptions?.notFoundText || '')"
                      [searchable]="input.selectOptions?.searchable || false"
                      [clearable]="input.selectOptions?.clearable || false"
                      [placeholder]="t(input.selectOptions?.placeholder || '')"
                      [ngClass]="{'is-invalid': isSelectInvalid(input)}"
                      [readonly]="input.selectOptions?.disabled || false"
                      [(ngModel)]="selectValues[input.alias ? input.alias : input.fieldName]" [appendTo]="'body'"
                      (ngModelChange)="onSelectChange(input, $event)">
                      <ng-template ng-label-tmp let-item="item" let-index="index">
                        {{ t(item.name) }}
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                        {{ t(item.name) }}
                      </ng-template>
                    </ng-select>
                    <div class="invalid-feedback">
                      {{t('msg_validation_required_field')}}
                    </div>
                  </div>
                </div>

                <div *ngSwitchCase="'list'">
                  <br>
                  <div *ngIf="input.rowConfig && input.rowConfig.subRowConfig" class="mb-3">
                    <label class="form-label">
                      <span class="text-danger fw-bold me-2" *ngIf="input.required">*</span>
                      {{ t(input.key) }}
                    </label>
                    <div class="table-container">
                      <table *transloco="let t" class="table table-borderless table-mx-content only-outside">
                        <caption class="opacity-0">{{t('txt_list_of_data')}}</caption>
                        <thead>
                          <tr class="p-2">
                            <th [ngClass]="{'th-action': header.columnType === columnTypeEnum.DELETE_ACTION}"
                              [ngStyle]="{'background-color': header.color}"
                              *ngFor="let header of input.rowConfig.subRowConfig[0].tableConfig.headers">
                              {{t(header.name)}}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngIf="input.value.length === 0">
                            <td [attr.colspan]="input.rowConfig.subRowConfig[0].tableConfig.headers.length">
                              <app-empty-panel emptyMessage="txt_empty" icon="inventory_2"
                                [emptyPanelStyle]="false"></app-empty-panel>
                            </td>
                          </tr>
                          <ng-template ngFor let-data [ngForOf]="input.value" let-i="index">
                            <tr
                              [ngClass]="{'blinking-item': isInfoIncomplete(input.rowConfig.subRowConfig[0].tableConfig, data)}"
                              class="p-2">
                              <td [ngClass]="{
                                'th-action': header.columnType === columnTypeEnum.DELETE_ACTION,
                                'blinking-cell': isCellIncomplete(header.fieldName,input.rowConfig.subRowConfig[0].tableConfig.inputConfig,data)
                                }" *ngFor="let header of input.rowConfig.subRowConfig[0].tableConfig.headers"
                                class="cursor-pointer" appDoubleClick
                                (doubleClick)="doubleHandler(data, genericModal2, input.rowConfig.subRowConfig[0].tableConfig.inputConfig)">
                                <div *ngIf="header.columnType !== columnTypeEnum.DELETE_ACTION">
                                  {{getCellText(data[header.fieldName],header.columnType)}}
                                </div>
                                <div *ngIf="header.columnType === columnTypeEnum.DELETE_ACTION">
                                  <button (click)="appConfirmDeleteItem2.openModal([input.value, i])"
                                    class="btn btn-text-danger">{{t('txt_delete')}}</button>
                                </div>
                              </td>
                            </tr>

                            <app-generic-modal #genericModal2 [modalCentered]="true" bodyTxt=""
                              [title]="isCreation ? 'txt_create' : 'txt_edit'" modalSize="xl"
                              (actionConfirm)="configsFinal($event)" (actionCancel)="closeModal()"></app-generic-modal>

                            <app-confirm #appConfirmDeleteItem2 (actionConfirm)="removeItem($event)"
                              [modalCentered]="true" bodyTxt="">
                            </app-confirm>

                          </ng-template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </ng-container>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="closeBtn()"
      data-dismiss="modal">{{t('cancel')}}</button>
    <button type="button" class="btn" (click)="confirmBtn()" class="btn btn-primary">
      {{t(btnTxt)}}
    </button>
  </div>
</ng-template>
