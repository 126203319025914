<div class="d-flex justify-content-end gap-2 align-items-center" *transloco=" let t">
  <div class="d-flex flex-column flex-lg-row justify-content-start gap-2 align-items-center" *ngIf="isAnySelected">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="select-all" [checked]="isAllSelected"
        (change)="_onSelectAll($event)">
      <label class="form-check-label" for="select-all">
        {{t('select_all')}}
      </label>
    </div>
    <button class="btn btn-sm btn-gray" (click)="_onDeselectAll()">
      {{t('deselect')}}
    </button>
    <button *ngIf="_showDeleteButton" class="btn btn-sm btn-danger" (click)="_deleteAction()">
      {{t('txt_delete')}}
      <small>({{ totalSelected }})</small>
    </button>
  </div>
</div>
