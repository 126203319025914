import { Component } from '@angular/core';
import { LoadingControlService } from 'src/app/app-commons/components/loading-indicator/loading-control.service';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent {

  constructor(
    public loadingControlService: LoadingControlService
  ) {
  }
}
