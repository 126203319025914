import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss']
})
export class TranslateComponent implements OnInit{

  @Input() tinyTranslate: boolean = true;
  langs: any[] = [];
  language: string = '';

  constructor(private translocoService:TranslocoService){
   
  }

  ngOnInit(): void {
    this.langs = this.translocoService.getAvailableLangs();
    this.changeLang(this.translocoService.getActiveLang());
  }


  changeLang(lang: any) {
    //set lenguage using the langs array
    this.langs.forEach((element: any) => {
      if(element.id == lang){
        this.language = element.label;
        //set lenguage in localstorage
        localStorage.setItem('language', lang);
      }
    });

    this.translocoService.setActiveLang(lang);
  }

}
