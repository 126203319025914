import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { HeaderEnum } from 'src/global/header-enum';
import { PermissionTypeEnum, PermissionsEnum } from 'src/global/permission-utils';
import { TabConfig } from './tabs-configs';

/**
 * HeaderComponent
 * @var  {string} title  The title for the view
 * @var  {HeaderEnum} type Filter of buttons needed
 * @var  {string} editRoute If the view uses a edit button this is going to be the route to navigate
 * @var  {string} createRoute If the view uses a save button this is going to be the route to navigate
 * @emits  saveAction When the save button is clicked, this component sends a signal to its parent component
 * @emits  deleteAction When the delete button is clicked, this component sends a signal to its parent component
 */

type customType = any[] | string | null;

interface CombinedData {
  asc: boolean;
  tabSelected: number;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService
  ){}

  @Input() title = '';
  @Input() type: HeaderEnum = HeaderEnum.VIEW;
  @Input() showEditButtonInput: boolean = true;
  @Input() editRoute: customType = '';
  @Input() createRoute: customType = '';
  @Input() cancelRoute: customType = '../';
  @Input() tabs: TabConfig[] = [];
  @Input() edit: boolean = false;
  @Output() tabSelected = new EventEmitter<number>();
  @Input() validateCompleteTab = false;
  @Input() showBreadCrumbs: boolean = true;
  @Input() isCreateAction=false;
  @Input() createText: string = 'create';
  @Input() showName: boolean = true;
  @Input() loadComponent: boolean = false;
  @Input() noNavigation: boolean = false;

  /**
   * Show or not the delete button
   */
  @Input() showDeleteAction: boolean = true;
  /**
   * Show or not the tabs numeration
   */
  @Input() showTabNumbers: boolean = false; // Nueva propiedad para mostrar/ocultar la numeración de las pestañas
  /**
   * Show or not the tabs check
   *
  */
  @Input() showTabCheck: boolean = false; // Nueva propiedad para mostrar/ocultar el check de las pestañas

  @Output() saveAction = new EventEmitter<void>();
  @Output() deleteAction = new EventEmitter<void>();
  @Output() openAction = new EventEmitter<void>();
  @Output() closeAction = new EventEmitter<void>();
  @Output() createAction = new EventEmitter<void>();


  @ViewChild('navigationTab') navigationTab2!: ElementRef;

  headerEnum = HeaderEnum;
  isOverflow = false;
  scrollLeft = false;
  scrollRight = false;

  isBackTwice: boolean = false;
  isNotFound: boolean = false;
  currentLanguage: string = '';

  /**
   * Permissions
   *
   */
  @Input() permission: number = PermissionsEnum.NONE;
  permissionType = PermissionTypeEnum;

  @Input() changing!: Subject<CombinedData>;
  //@Input() changing: Subject<CombinedData> = new Subject<CombinedData>();
  ngOnInit(){
    if(this.changing !== undefined){
      this.changing.subscribe(asc => {
        this.navigate(asc.asc, asc.tabSelected);
      });
    }
    this.currentLanguage = this.translocoService.getActiveLang();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkOverflow();
    }, 250);
  }

  navigate(asc: boolean, tabSelected: number){
    if(asc){
      if(tabSelected < this.tabs.length - 1){
        this.tabs[tabSelected].complete = true;
        this.tabs[tabSelected].view = false;
        this.tabs[tabSelected + 1].view = true;
      }
    }else{
      if(tabSelected > 0){
        this.tabs[tabSelected].view = false;
        this.tabs[tabSelected - 1].view = true;
        this.tabs[tabSelected - 1].complete = false;
      }
    }
  }

  // Método para restablecer la propiedad 'complete' de todas las pestañas a 'false'
  resetTabCompleteness() {
    this.tabs.forEach(tab => {
      tab.complete = false;
    });
  }


  save() {
    this.saveAction.emit();
  }

  delete() {
    this.deleteAction.emit();
  }

  open() {
    this.openAction.emit();
  }

  close() {
    this.closeAction.emit();
  }

  create() {
    this.createAction.emit();
  }
  createRouteAction(event: Event){
    if(this.loadComponent){
      event.preventDefault();
      this.createAction.emit();
      return  ;
    }
    this.router.navigate([this.createRoute], { relativeTo: this.route });
  }

  selectTab(position: number) {
    if (this.validateCompleteTab) {
      if (!this.tabs[position].complete) {
        return
      }
    }

    this.tabs.forEach(tab => {
      tab.view = false;
    });
    this.tabs[position].view = true;
    this.tabSelected.emit(position);
  }

  checkOverflow() {
    let element = this.navigationTab2.nativeElement as HTMLElement;
    if (element.offsetWidth < element.scrollWidth) {
      this.isOverflow = true;
      this.scrollLeft = element.scrollLeft != 0;
      this.scrollRight = element.scrollLeft != (element.scrollWidth - element.clientWidth);
    } else {
      this.isOverflow = false;
    }
  }

  scrollingLeft() {

    let element = this.navigationTab2.nativeElement as HTMLElement;

    element.scrollLeft -= 50;
  }

  scrollingRight() {
    let element = this.navigationTab2.nativeElement as HTMLElement;
    element.scrollLeft += 50;
  }

  get showDeleteButton(): boolean {
    return (this.type === HeaderEnum.VIEW
      || this.type === HeaderEnum.VIEW_OPEN
      || this.type === HeaderEnum.VIEW_CLOSE)
      && this.deleteAction.observed
      && this.showDeleteAction;
  }

  get showEditButton(): boolean {
    return (this.type == HeaderEnum.VIEW
      || this.type == HeaderEnum.VIEW_OPEN
      || this.type == HeaderEnum.VIEW_CLOSE)
      && !!this.editRoute;
  }

  return() {
    const url = this.router.url;
    const isHomePage = url === '/'; // Ruta de inicio
    const isAdminPage = url.startsWith('/administration'); // Ruta de administración
    const isDemoPage = url.startsWith('/demo'); // Ruta de demo
    const isSettingPage = url.startsWith('/setting'); // Ruta de configuración


    // Lista de rutas iniciales que no deben mostrar la flecha de retorno
    const excludeFromBackNavigation = ['/', '/administration/log', '/demo', '/administration/package', '/administration/sync', '/setting/type', '/setting/subtype', '/setting/class', '/setting/screen'];

    if (isHomePage || excludeFromBackNavigation.includes(url)) {
      // Si estamos en la página de inicio o en una de las rutas excluidas, no hacemos nada
      return;
    }

    if (isAdminPage) {
      // Si estamos en la página de administración, manejamos la navegación aquí
      const adminUrl = '/administration'; // Ruta de administración

      if(url.startsWith(adminUrl + "/logbook")){
        void this.router.navigate([adminUrl + '/log']);
      }

      if(url.startsWith(adminUrl + "/package")){
        void this.router.navigate([adminUrl + '/package']);
      }

      return;
    }

    if (isDemoPage) {
      // Si estamos en la página de demo, manejamos la navegación aquí
      const demoUrl = '/demo'; // Ruta de demo

      if (url === demoUrl) {
        // Si estamos en la página de demo principal, navegamos a la página de inicio
        void this.router.navigate(['/']);
      } else {
        // Si estamos en una subruta de demo, navegamos atrás
        void this.router.navigate(['../'], { relativeTo: this.route });
      }

      return;
    }

    if(isSettingPage){
      // Si estamos en la página de configuración, manejamos la navegación aquí
      const adminUrl = '/setting'; // Ruta de administración
      const adminRoutes = ['type', 'subtype', 'class', 'screen']; // Rutas de administración

      if(url.startsWith(adminUrl + "/screen/view")){
        void this.router.navigate([adminUrl + '/screen']);
        return;
      }

      if(url.startsWith(adminUrl + "/screen/edit")){
        void this.router.navigate([adminUrl + '/screen']);
        return;
      }

      if (adminRoutes.some(route => url.startsWith(`${adminUrl}/${route}`))) {
        // Si estamos en una de las rutas de configuración, navegamos atrás
        void this.router.navigate(['../'], { relativeTo: this.route });
      } else {
        // Si no estamos en una ruta específica de configuración, navegamos a la página de inicio
        void this.router.navigate(['/']);
      }

      return;
    }

    // Si no estamos en ninguna de las rutas anteriores, navegamos a la página de inicio
    void this.router.navigate(['/']);
  }

  showBackButton(): boolean {
    const url = this.router.url;
    const excludedRoutes = ['/', '/administration/log', '/demo', '/administration/package', '/administration/sync','/setting/type', '/setting/subtype', '/setting/class', '/setting/screen'];

    // Verifica si la ruta actual está en la lista de rutas excluidas
    return !excludedRoutes.includes(url);
  }

}
