import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterSearch } from "src/app/app-commons/components/search-filter/filter-util";

const FILTER_PAG_REGEX = /\D/g;

/**
 * Component to handle pagination. Example usage:
 *
 * @Example
 * ```html
 * <app-pagination
 *  [(paginationConfig)]="myFilters"
 *  [currentCollectionSize]="myCollection.length"
 *  (onChange)="onFilterChange()"
 * >
 * </app-pagination>
 * ```
 */
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  /**
   * output that is emitted when any property of the pagination (max or pag) has changed
   */
  @Output() paginationConfigChange = new EventEmitter<FilterSearch>();

  /**
   * input that receives the pagination configuration
   * @param {FilterSearch} config
   */
  @Input() set paginationConfig(config: FilterSearch) {

    this._paginationConfig = config;
    this.copyPage = config.page + 1;
  }

  /**
   * event that notifies of any change in the pagination properties
   */
  @Output() onChange = new EventEmitter();

  /**
   * current size of the collection shown in any view
   */
  @Input() currentCollectionSize = 0;

  _paginationConfig!: FilterSearch;

  copyPage!: number;

  selectPage(page: string, pages: number, input: HTMLInputElement) {
    const aux = parseInt(page, 10) || 1;
    this.copyPage = aux > pages ? 1 : aux;
    if (pages <= 0) {
      this.copyPage = 1;
    }
    input.value = this.copyPage.toString();
    this.onEmit();
  }

  formatInput(input: HTMLInputElement) {
    input.value = input.value.replace(FILTER_PAG_REGEX, '');
  }

  onPageChange(page: number) {
    this.onEmit();
  }

  selectMax(max: number) {
    const numberOfPages = Math.ceil(this._paginationConfig.collectionSize / max) || 1;

    if (this.copyPage > numberOfPages) {
      this.copyPage = numberOfPages;
      this._paginationConfig.limit = max;

      this.onEmit();
      return;
    }

    this._paginationConfig.limit = max;
    this.onEmit();
  }

  private onEmit() {
    this._paginationConfig.page = this.copyPage - 1;
    // save on localstorage
    localStorage.setItem(this._paginationConfig.filter_id, JSON.stringify(this._paginationConfig));

    // emit changes
    this._paginationConfig = JSON.parse(JSON.stringify(this._paginationConfig))
    this.paginationConfigChange.emit(this._paginationConfig);
    this.onChange.emit();
  }
}
