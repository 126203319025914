import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // Importa ReactiveFormsModule
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppCommonsModule } from './app-commons/app-commons.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingUiInterceptor } from './conf/loading-ui.interceptor';
import { SecureInterceptor } from './conf/security.interceptor';
import { TranslocoRootModule } from './transloco-root.module';



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    HttpClientXsrfModule.disable(),
    TranslocoRootModule,
    AppCommonsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoadingUiInterceptor, multi: true },
    {provide: HTTP_INTERCEPTORS, useClass: SecureInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
