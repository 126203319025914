import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  constructor(
    protected modalService: NgbModal
  ) { }

  @Input() backdrop: boolean | 'static' = true;
  @Input() modalTitle = 'Nuevo modal';
  @Input() modalSize = 'lg';
  @Input() modalScrollable = true;
  @Input() modalCentered = false;
  @Input() showCancelButton = true;
  @Input() showAcceptButton = true;
  @Input() valid = true;
  @Input() textAccept = 'Aceptar';
  @Input() keyboard = true;

  @Input() showCustomButton = false;
  @Input() textCustomButton = 'Custom';
  @Output() customButtonCallback = new EventEmitter<boolean>();

  @Input() showFooter = true;


  @ViewChild('content') content!: ElementRef;
  closeResult = '';
  @Output() modalCallback = new EventEmitter<boolean>();
  modalRef!: NgbModalRef;

  cancel(): void {
    this.modalCallback.emit(false);
    this.close();
  }

  accept(): void {
    if(this.valid){
      this.modalCallback.emit(true);
      this.close();
    }
  }

  customButton(): void {
    this.customButtonCallback.emit(true);
  }

  open(): void {
    this.modalRef = this.modalService.open(this.content, {
      size: this.modalSize,
      scrollable: this.modalScrollable,
      centered: this.modalCentered,
      backdrop: this.backdrop,
      keyboard: this.keyboard,
      modalDialogClass: 'myCustomAnimation',
    });

    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  protected close(): void {
    this.modalRef.close();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
