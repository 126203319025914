import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Reusable component for actions
 *
 * @Example
 * ```html
 * <td class="d-flex justify-content-end">
 *  <app-action
 *    [tpStatus]="admRole.entity.tpStatus"
 *    [viewRoute]="['view', admRole.entity.hashId]"
 *    [editRoute]="['/security/roles/edit', admRole.entity.hashId]"
 *    (deleteAction)="appConfirmDeleteRole.openModal(admRole.entity.hashId)"
 *    [permission]="permissionsEnum.ROLES"
 *  >
 *    <button
 *      class="dropdown-item"
 *      (click)="anotherAction(admRole.roleId)"
 *      ngbDropdownItem
 *    >
 *      Another button
 *    </button>
 *  </app-action>
 * </td>
 * ```
 */
@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent {
  /**
   * input that receives the routes configuration for the items of the component (also items of the dropdown)
   */
  @Input() routes: { name: string, commands?: any[] | string | null }[] = [];

  /**
   * output that is emitted when the delete button is clicked
   */
  @Output() deleteAction = new EventEmitter();

  /**
   * indicate if the delete button is shown
   */
  @Input() showDeleteAction: boolean = true;

  /**
   * routes for view and edition
   */
  @Input() editRoute: any[] | string | null | undefined;
  @Input() viewRoute: any[] | string | null | undefined;
  @Input() viewHandler: Function | null | undefined;


  @Input() specialActions = false;

  /**
   * To know if the dropdown is being displayed
   */
  @Output() isDisplayed = new EventEmitter<boolean>();

  onDeleteAction() {
    this.deleteAction.emit();
  }

  get isShown() {
    return (this.routes && this.routes.length)
      || this.showDeleteButton
      || this.showEditButton
      || this.viewRoute !== undefined
      || this.specialActions;
  }

  get showDeleteButton() {
    return this.deleteAction.observed && this.showDeleteAction;
  }

  get showEditButton(): boolean {
    return !!this.editRoute;
  }

  changeDropdown(isDisplayed: any) {
    this.isDisplayed.emit(isDisplayed);
  }
}
