<ng-container *transloco="let t">
    <div *ngIf="sideNavStatus" class="side-nav-content">
        <ng-container>
           <div>
            <ul class="nav-list">
                <li class="nav-list-item" routerLinkActive="active">
                    <app-material-icon class="nav-list-item-icon">
                        history
                    </app-material-icon>
                    <span>
                        <a class="nav-list-item-link" [routerLink]="[logRoute]">{{t('log')}}</a>
                    </span>
                </li>
                <li class="nav-list-item" routerLinkActive="active">
                    <app-material-icon class="nav-list-item-icon">
                        cloud_upload
                    </app-material-icon>
                    <span>
                        <a class="nav-list-item-link" [routerLink]="[packageRoute]">{{t('txt_package')}}</a>
                    </span>
                </li>
                <li class="nav-list-item" routerLinkActive="active" (click)="refresh()">
                    <app-material-icon class="nav-list-item-icon">
                        sync
                    </app-material-icon>
                    <span>
                        <a class="nav-list-item-link" [routerLink]="[syncRoute]">{{t('txt_synchronization')}}</a>
                    </span>
                </li>
            </ul>
            <div>
                <label class="icon-color">{{t('txt_configuration')}}</label>
                    <div class="drop_container row">
                        <div class="col">
                            <div ngbDropdown display="dynamic" placement="bottom-end" class="d-inline-block">
                                <button type="button " class="btn d-flex align-items-center btn-list-item" id="catalog" ngbDropdownToggle (click)="toggleDropdown()">
                                    <app-material-icon *ngIf="dropdownStatus">
                                        keyboard_arrow_down
                                    </app-material-icon>
                                    <app-material-icon *ngIf="!dropdownStatus">
                                        keyboard_arrow_right
                                    </app-material-icon>
                                    <app-material-icon>
                                        add_circle
                                    </app-material-icon>
                                    <span>
                                        <p class="text-center m-0">{{t('txt_catalogs')}}</p>
                                    </span>
                                </button>
                                <div ngbDropdownMenu aria-labelledby="catalog">
                                    <button  ngbDropdownItem [routerLink]="[typeRoute]" routerLinkActive="active">
                                        <p>{{t('txt_type')}}</p>
                                    </button>
                                    <button ngbDropdownItem [routerLink]="[subtypeRoute]" routerLinkActive="active">
                                        <p>{{t('txt_subtypes')}}</p>
                                    </button>
                                    <button ngbDropdownItem [routerLink]="[classRoute]" routerLinkActive="active">
                                        <p>{{t('txt_classes')}}</p>
                                    </button>
                                    <button ngbDropdownItem [routerLink]="[screenRoute]" routerLinkActive="active">
                                        <p>{{t('txt_screens')}}</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
           </div>
            <div>
                <div *ngIf="false" class="sync-container d-flex flex-column">
                    <span>{{t('txt_last_synchronization')}}</span>
                    <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                        <option selected>Guatemala</option>
                        <option value="1">El Salvador</option>
                        <option value="2">Honduras</option>
                        <option value="3">Costa Rica</option>
                    </select>

                    <span>{{this.formattedDate}}</span>
                    <span>{{this.currentTime}}</span>
                </div>

                <!-- Translation -->
                <app-translate [tinyTranslate]="sideNavStatus"></app-translate>
            </div>
        </ng-container>
    </div>

      <!-- Sidenav Close  Items-->
    <div class="side-nav-content" *ngIf="!sideNavStatus">
        <ul class="nav-list">
            <li class="nav-list-item" routerLinkActive="active">
                <a class="nav-list-item-link" [routerLink]="[logRoute]">
                    <app-material-icon class="nav-list-item-icon">
                        history
                    </app-material-icon>
                </a>
            </li>
            <li class="nav-list-item" routerLinkActive="active">
                <a class="nav-list-item-link" [routerLink]="[packageRoute]">
                    <app-material-icon class="nav-list-item-icon">
                        cloud_upload
                    </app-material-icon>
                </a>
            </li>
            <li class="nav-list-item" routerLinkActive="active">
                <a class="nav-list-item-link" [routerLink]="[syncRoute]" (click)="refresh()">
                    <app-material-icon class="nav-list-item-icon">
                        sync
                    </app-material-icon>
                </a>
            </li>
            <li class="nav-list-item d-flex flex-column cursor-pointer" display="dynamic" placement="bottom-start" container="body" ngbDropdown routerLinkActive="active">
                <div>
                    <app-material-icon class="nav-list-item-icon" ngbDropdownToggle>
                        add_circle
                    </app-material-icon>
                    <div ngbDropdownMenu class="end-0">
                        <button ngbDropdownItem [routerLink]="[typeRoute]" routerLinkActive="active">
                            <p>{{t('txt_type')}}</p>
                        </button>
                        <button ngbDropdownItem [routerLink]="[subtypeRoute]" routerLinkActive="active">
                            <p>{{t('txt_subtypes')}}</p>
                        </button>
                        <button ngbDropdownItem [routerLink]="[classRoute]" routerLinkActive="active">
                            <p>{{t('txt_classes')}}</p>
                        </button>
                        <button ngbDropdownItem [routerLink]="[screenRoute]" routerLinkActive="active">
                            <p>{{t('txt_screens')}}</p>
                        </button>
                    </div>
                </div>
            </li>
        </ul>
        <!-- Translation close -->
        <app-translate [tinyTranslate]="sideNavStatus"></app-translate>
    </div>

</ng-container>
