import { FilterSearch } from "src/app/app-commons/components/search-filter/filter-util";


export const FilterClasses: FilterSearch = {
    filter_id: 'classes-list-component-filter-0',
    max: 10,
    limit: 10,
    page: 1,
    collectionSize: 0,
    asc: true,
    columnOrder: 'classes',
    country_id: null,
    channel: null,
  };
