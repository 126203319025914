<div *ngIf="loadingControlService.loading" class="loading-indicator-container">
  <div class="loading-text-container border-bottom border-top d-flex flex-column rounded-4 shadow-sm" *transloco="let t">
    <h2 class="mx-auto fw-bold fs-3 text-primary">{{t('txt_loading')}}</h2>
    <span class="text-muted mx-4 text-center">
      {{t('txt_loading_complement')}}
    </span>
    <div class="mx-auto mt-4">
      <div class="load-3">
        <div class="line mx-2"></div>
        <div class="line mx-2"></div>
        <div class="line mx-2"></div>
        <div class="line mx-2"></div>
      </div>
    </div>
  </div>
</div>
