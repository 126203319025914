import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { EmptyPanelComponent } from 'src/app/app-commons/components/empty-panel/empty-panel.component';
import { HeaderComponent } from 'src/app/app-commons/components/header/header.component';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { LoadingUiInterceptor } from '../conf/loading-ui.interceptor';
import { TranslocoRootModule } from '../transloco-root.module';
import { ActionComponent } from './components/action/action.component';
import { BodyComponent } from './components/body/body.component';
import { FileDragAndDropComponent } from './components/file-drag-and-drop/file-drag-and-drop.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { MaterialIconComponent } from './components/material-icon/material-icon.component';
import { ConfirmComponent } from './components/modal/confirm/confirm.component';
import { GenericModalComponent } from './components/modal/generic-modal/generic-modal.component';
import { ModalComponent } from './components/modal/modal.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { SettingComponent } from './components/setting/setting.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CustomRowComponent } from './components/table-v2/custom-row/custom-row.component';
import { MainTableComponent } from './components/table-v2/main-table/main-table.component';
import { TableComponent } from './components/table/table.component';
import { ToSelectComponent } from './components/to-select/to-select.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { TranslateComponent } from './components/translate/translate.component';
import { DoubleClickDirective } from './directives/double-click-directive';
import { FocusOnDirective } from './directives/focus-on.directive';
import { NgVar } from './directives/ng-var.directive';


@NgModule({
  declarations: [
    MaterialIconComponent,
    ToasterComponent,
    HeaderComponent,
    EmptyPanelComponent,
    BodyComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    SearchFilterComponent,
    ToSelectComponent,
    PaginationComponent,
    ActionComponent,
    NotFoundComponent,
    TranslateComponent,
    ModalComponent,
    SettingComponent,
    TableComponent,
    FileDragAndDropComponent,
    ConfirmComponent,
    MainTableComponent,
    CustomRowComponent,
    DoubleClickDirective,
    NgVar,
    LoadingIndicatorComponent,
    LoadingIndicatorComponent,
    GenericModalComponent,
    FocusOnDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    TranslocoRootModule,
    BreadcrumbModule,
    //RouterLink,
    NgSelectModule,
    DigitOnlyModule,
    RouterModule
  ],
  exports: [
    ToasterComponent,
    MaterialIconComponent,
    HeaderComponent,
    EmptyPanelComponent,
    BodyComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    SearchFilterComponent,
    ToSelectComponent,
    PaginationComponent,
    ActionComponent,
    NotFoundComponent,
    TranslateComponent,
    ModalComponent,
    SettingComponent,
    TableComponent,
    FileDragAndDropComponent,
    ConfirmComponent,
    DoubleClickDirective,
    NgVar,
    LoadingIndicatorComponent,
    LoadingIndicatorComponent,
    GenericModalComponent,
    FocusOnDirective,
  ],
  providers: [
    { provide: LoadingUiInterceptor, useClass: LoadingUiInterceptor, multi: true }
  ]
})
export class AppCommonsModule { }
