import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnConfig, FilterSearch, ObjectFilter } from "src/app/app-commons/components/search-filter/filter-util";

/**
 * Component to handle the selection of one or several entities
 *
 * @Example
 * ```html
 *  <app-to-select
 *    [isAnySelected]="isAnySelected"
 *    [isAllSelected]="isAllSelected"
 *    [totalSelected]="totalSelected"
 *    (onSelectAll)="onSelectAll($event)"
 *    (onDeselectAll)="onDeselectAll()"
 *    (deleteAction)="appConfirmDeleteRoles.openModal(undefined)"
 *    [permission]="permissionsEnum.USERS"
 *
 *    [filters]="filters"
 *    [objectFilter]="objectFilter"
 *    [columns]="columns"
 *    (exportEvent)="export($event)"
 *  >
 *  </app-to-select>
 * ```
 */
@Component({
  selector: 'app-to-select',
  templateUrl: './to-select.component.html',
  styleUrls: ['./to-select.component.scss']
})
export class ToSelectComponent {

  @Input() isAnySelected: boolean = false;
  @Input() isAllSelected: boolean = false;
  @Input() totalSelected: number = 0;

  /**
   * Show or not the delete button
   */
  @Input() showDeleteAction: boolean = true;

  @Output() onSelectAll = new EventEmitter<Event>();
  @Output() onDeselectAll = new EventEmitter<void>();
  @Output() deleteAction = new EventEmitter<void>();

  /**
   * export component attributes, see {@link ExportComponent}
   */
  @Input() filters!: FilterSearch;
  @Input() objectFilter!: ObjectFilter;
  @Input() columns!: ColumnConfig;
  @Input() isPdfAvailable: boolean = false;

  _onSelectAll(event: Event) {
    this.onSelectAll.emit(event);
  }

  _onDeselectAll() {
    this.onDeselectAll.emit();
  }

  _deleteAction() {
    this.deleteAction.emit();
  }

  get _showDeleteButton() {
    return this.deleteAction.observed && this.showDeleteAction;
  }
}
