import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService, IUser } from 'src/app/services/cognito/cognito.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {


  @Output() sideNavToggled = new EventEmitter<boolean>();
  menuStatus: boolean = false;
  user: IUser;
  email: string = '';
  usernameInitials: string = '';
  version = environment.version;

  constructor(
    private router: Router,
    private cognitoService:CognitoService
    ) {
    this.user = {} as IUser;
   }

  ngOnInit(): void {
   this.getUser();
  }

  getUser(): void {
    this.cognitoService.getUser().then((user) => {
      this.email = user.attributes.email;
      localStorage.setItem('email', this.email);
      this.usernameInitials = user.username.slice(0,2).toUpperCase();
    }
    ).catch((error) => {
      console.log(error);
    });
  }

  signOut(): void {
    this.cognitoService.signOut()
    .then(() => {
      this.router.navigate(['/']);
      localStorage.clear();
    });
  }

  SideNavToggle() {
    this.menuStatus = !this.menuStatus;
    this.sideNavToggled.emit(this.menuStatus);
  }
}
