<div class="pagination-container d-flex flex-column flex-lg-row gap-2 justify-content-between mb-3"
  *ngIf="_paginationConfig">
  <div class="max d-flex flex-row gap-3 text-color-gray-600 align-items-center fs-14 " *transloco="let t">
    <!--Has an error when displaying multiple elements, pagination breaks-->
    <div *ngIf="false">
      {{t('txt_rows_to_show')}}
    </div>
    <div *ngIf="false" ngbDropdown placement="top-end" class="d-inline-block outline text-color-gray-800">
      <button type="button" class="btn btn-outline" id="dropdownMax" ngbDropdownToggle>
        <div class="d-flex align-items-center  ">
          <span class="">{{ this._paginationConfig.limit }}</span>
          <app-material-icon class="icon text-color-gray-500">expand_more</app-material-icon>
        </div>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownMax" class="dropdownMenu">
        <button ngbDropdownItem (click)="selectMax(10)">10</button>
        <button ngbDropdownItem (click)="selectMax(20)">20</button>
        <button ngbDropdownItem (click)="selectMax(30)">30</button>
      </div>
    </div>
    <div class="d-flex h-100">
      <div class="vl">
      </div>
    </div>
    {{t('txt_showing')}} {{ currentCollectionSize + ((copyPage-1) * _paginationConfig.max) }} {{t('txt_results_to')}} {{ this._paginationConfig.collectionSize }}
  </div>

  <ngb-pagination [collectionSize]="_paginationConfig.collectionSize" [(page)]="copyPage"
    [boundaryLinks]="true" class="" (pageChange)="onPageChange($event)" [pageSize]="_paginationConfig.max">
    <ng-template ngbPaginationFirst>
      <div class="d-flex align-items-center">
        <app-material-icon>keyboard_double_arrow_left</app-material-icon>
      </div>
    </ng-template>
    <ng-template ngbPaginationLast>
      <div class="d-flex align-items-center">
        <app-material-icon>keyboard_double_arrow_right</app-material-icon>
      </div>
    </ng-template>
    <ng-template ngbPaginationPrevious>
      <div class="d-flex align-items-center">
        <app-material-icon>chevron_left</app-material-icon>
      </div>
    </ng-template>
    <ng-template ngbPaginationNext>
      <div class="d-flex align-items-center">
        <app-material-icon>chevron_right</app-material-icon>
      </div>
    </ng-template>
    <ng-template ngbPaginationPages let-page let-pages="pages">
      <ul>
        <li class="ngb-custom-pages-item text-color-gray-800 d-flex align-items-center " *ngIf="pages.length > 0">
          <div class="d-flex flex-nowrap px-2" *transloco="let t">
            <label id="paginationInputLabel" for="paginationInput"
              class="col-form-label me-2 ms-1">{{t('txt_page')}}</label>
            <input #i type="text" inputmode="numeric" pattern="[0-9]*" class="form-control custom-pages-input"
              id="paginationInput" [value]="page" (keyup.enter)="selectPage(i.value, pages.length, $any($event).target)"
              (blur)="selectPage(i.value, pages.length, $any($event).target)" (input)="formatInput($any($event).target)"
              aria-labelledby="paginationInputLabel paginationDescription" style="width: 2.5rem" />
            <span id="paginationDescription" class="col-form-label text-nowrap px-2">
              {{t('txt_of')}} {{ pages.length }}
            </span>
          </div>
        </li>
      </ul>
    </ng-template>
  </ngb-pagination>
</div>
