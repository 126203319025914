import { Component, OnInit } from '@angular/core';
import { ColumnConfig, FilterSearch, ObjectFilter } from 'src/app/app-commons/components/search-filter/filter-util';
import { SimpleList } from 'src/app/data/others/simple-list';
import { TableColumns, TableFilter, TableListFilter } from './table-list-filter';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent extends SimpleList implements OnInit {

  objectFilter: ObjectFilter = TableListFilter;
  filters: FilterSearch = TableFilter;
  columns: ColumnConfig = TableColumns;
  simpleList: { selected: boolean; entity: any; }[] = [];
  currentEditUrl!: string;

  ngOnInit(): void {
    this.findAll();
  }


  override onFilterChange(): void {
    this.findAll();
  }


  override checkSort(name: string, sort: boolean | undefined): boolean {
    if (name === this.filters.columnOrder) {
      return sort === undefined ? true : (sort === this.filters.asc);
    }
    return false;
  }

  override checkVisible(name: string): boolean {
    if (!this.columns.config[name]) {
      return false;
    }

    return !this.columns.config[name].default && !this.columns.config[name].selected;
  }

  override findAll(): void {
    this.simpleList = [
      { selected: false, entity: { email1: 'email1', name: 'name', role: 'role', tpStatus: 'tpStatus', actions: 'actions' } },
      { selected: false, entity: { email1: 'email1', name: 'name', role: 'role', tpStatus: 'tpStatus', actions: 'actions' } }
    ]
    this.filters.collectionSize = 2;
  }

}
