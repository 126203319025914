import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizeGuard } from './AuthorizeGuard';
import { NotFoundComponent } from './app-commons/components/not-found/not-found.component';
import { TableComponent } from './app-commons/components/table/table.component';


const routes: Routes = [
  //* modules
  { path: '', loadChildren: () => import('./features/start/start.module').then(m => m.StartModule)},
  { path: 'administration', loadChildren: () => import('./features/administration/administration.module').then(m => m.AdministrationModule), canActivate: ([AuthorizeGuard]) },
  { path: 'setting', loadChildren: () => import('./features/configuration/configuration.module').then(m => m.ConfigurationModule), canActivate: ([AuthorizeGuard]) },
  {
    path: 'demo', children: [
      { path: '', redirectTo: 'test', pathMatch: 'full' },
      { path: 'table', component: TableComponent }
    ]
    ,
    canActivate: ([AuthorizeGuard])
  },
  { path: '**', component: NotFoundComponent, data: { '404': true }, canActivate: ([AuthorizeGuard]) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

