import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  title = 'txt_site_on_build';
  message = 'txt_site_on_build_info';
  icon = 'construction';

  sideNavStatus: boolean = false;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe({
      next: value => {
        if (value['404']) {
          this.title = 'txt_page_not_found'
          this.icon = 'block'
          this.message = 'txt_page_not_found_info';
        }
      }
    });
  }
}
