import { RowConfig } from "src/app/app-commons/components/table-v2/table-util";

export interface InputConfig {
  key: string;
  value: any;
  required: boolean;
  inputType: 'text' | 'number' | 'textarea' | 'date' | 'select' | 'label' | 'object' | 'list';
  lengthType?: number;
  textPlaceholder?: string;
  fieldName?: string;
  readonly?: boolean;
  selectOptions?: SelectOptions;
  objectConfig?: InputConfig[];
  alias?: string;
  rowConfig?: RowConfig;
}

export interface SelectOptions {
  items: any[]; // Opciones del select
  bindLabel: string; // Propiedad a mostrar como etiqueta
  bindValue: string; // Propiedad a asociar al valor seleccionado
  notFoundText: string;
  clearable: boolean;
  searchable: boolean;
  placeholder: string;
  disabled: boolean;
  selectedItem?: any;
}

export interface SelectValue {
  [key: string]: number | undefined;
}


export function getInputConfig(data: any, inputArticle: InputConfig[]) {
  let copyArticle = JSON.parse(JSON.stringify(inputArticle));
  for (const input of copyArticle) {
    let object = data[input.fieldName];
    if(object === undefined || object === null) {
      continue;
    }
    if(input.inputType === 'object'){
      object = getInputConfig(object, input.objectConfig);
    }
    input.value = object;
  }
  return copyArticle;
}

export function setInputConfig(data: any, inputArticle: InputConfig[]) {
  let copyArticle = JSON.parse(JSON.stringify(inputArticle));
  for (let input of copyArticle) {
    if(input.inputType === 'object'){
      continue;
    }
    data[input.fieldName] = input.value;
  }
  return data;
}
