import { Component } from '@angular/core';

@Component({
  selector: 'app-custom-row',
  templateUrl: './custom-row.component.html',
  styleUrls: ['./custom-row.component.scss']
})
export class CustomRowComponent {

}
