import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ObjectValidatorService {

  isInfoIncomplete(base: any, data: any) {
    if (!base || !data) {
      return true;
    }
    for (const itemBase of base.inputConfig) {
      if (itemBase.required) {
        let name = this.getName(itemBase.fieldName);
        if (data[name] === undefined || data[name] === null || data[name] === '') {
          return true;
        }
      }
    }

    if (base.rows.length > 0) {
      //the configuration is the same, I only need one
      for (const itemBase of base.rows[0].subRowConfig) {
        let objectList = itemBase.fieldName !== 'exento_articulo' ? data[itemBase.fieldName] : [data[itemBase.fieldName]];
        for (const item of objectList) {
          if (!item) {
            continue;
          }
          let isInfoIncomplete = this.isInfoIncomplete(itemBase.tableConfig, item);
          if (isInfoIncomplete) {
            return true;
          }
        }
      }
    }

    return false;
  }

  isListInComplete(base: any, data: any) {
    for (const item of data) {
      let isInfoIncomplete = this.isInfoIncomplete(base, item.data);
      if (isInfoIncomplete) {
        return true;
      }
    }
    return false;
  }

  isCellIncomplete(fieldName: string, currentConfig: any, currentData: any) {
    let name = this.getName(fieldName);
    for (const config of currentConfig) {
      if (config.fieldName === fieldName) {
        if (config.required && (currentData[name] === undefined || currentData[name] === null || currentData[name] === '')) {
          return true;
        }
        break;
      }
    }
    return false;
  }

  isTabComplete(base: any, data: any) {
    for (const item of data) {
      let isInfoIncomplete = this.isInfoIncomplete(base, item.data);
      if (isInfoIncomplete) {
        return false;
      }
    }
    return true;
  }


  private getName(name: string) {
    if (name === 'class') {
      return 'class_id';
    }
    if (name === 'subtipo') {
      return 'subtype';
    }
    return name;
  }

}
