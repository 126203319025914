import packageInfo from 'package.json';

export const environment = {
    production: false,
    version: packageInfo.version,

    accessKey:'AKIAJNTLVRQW4DFMZW4A',
    secretKey:'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',

    cognitoIdentityPoolID: 'us-east-1:1f83eff5-78f6-421c-9dd5-f265e3b6aa21',
    
    bucketName: 's3-sincronizador-file-storage',
    bucketARN: 'arn:aws:s3:::s3-sincronizador-file-storage',
    region: 'us-east-1',
    cognito: {
        userPoolId: 'us-east-1_E4oxsXucX',
        userPoolWebClientId: '5623n39tfjin7eehvvavieds6v',
        region: 'us-east-1',
    },
    prodUrl: "https://1z9zk9vnt1.execute-api.us-east-1.amazonaws.com/prod/v1",
};
