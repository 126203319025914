import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CognitoService } from './services/cognito/cognito.service';

@Injectable({ providedIn: 'root' })
export class AuthorizeGuard {
  constructor(private cognitoService: CognitoService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //if is authenticated return true
    //else return false and redirect to login
    this.cognitoService
      .getAccessToken()
      .then((token) => {
        if (token) {
          localStorage.setItem('token', token);
          return this.cognitoService
            .isAuthenticated()
            .then((isAuthenticated: boolean) => {
              if (isAuthenticated) {

                return true;
              } else {
                this.router.navigate(['/']);
                return false;
              }
            });
        } else {
          this.router.navigate(['']);
          return false;
        }
      })
      .catch((err) => {
        this.router.navigate(['']);
        return false;
      });
    return true;
  }
}
