<ng-container *transloco="let t">
<div *ngIf="tinyTranslate"ngbDropdown class="dropdown w-100">
  <button ngbDropdownToggle class="btn bg-white btn-white w-100 border d-flex flex-row justify-content-start" type="button" id="dropdownMenuButton1" aria-expanded="false">
    <app-material-icon class="icon-color">
      translate
    </app-material-icon>
    {{ language }}
  </button>
  <ul ngbDropdownMenu aria-labelledby="dropdownMenuButton1">
    <li ngbDropdownItem *ngFor="let lang of langs" (click)="changeLang(lang.id)">
      <a class="dropdown-item">{{ lang.label }}</a>
    </li>
  </ul>
</div>

<div *ngIf="!tinyTranslate" ngbDropdown class="dropdown">
  <button ngbDropdownToggle class="btn btn-white border " type="button" id="dropdownMenuButton1" aria-expanded="false">
    <app-material-icon class="icon-color">
      translate
    </app-material-icon>
  </button>
  <ul ngbDropdownMenu aria-labelledby="dropdownMenuButton1">
    <li ngbDropdownItem *ngFor="let lang of langs" (click)="changeLang(lang.id)">
      <a class="dropdown-item">{{ lang.label }}</a>
    </li>
  </ul>
</div>
</ng-container>
