import { Component, Input } from '@angular/core';
import { TableConfig } from '../table-util';

@Component({
  selector: 'app-main-table',
  templateUrl: './main-table.component.html',
  styleUrls: ['./main-table.component.scss']
})
export class MainTableComponent {

  @Input() tableConfig!: TableConfig;

}
