<div class="table-actions d-flex flex-column flex-lg-row-reverse justify-content-end align-items-center gap-2"
  *transloco="let t">

  <!-- columns -->
  <div ngbDropdown *ngIf="_columns.length" autoClose="outside">
    <button class="btn btn-sm btn-gray" id="columns-dropdown" ngbDropdownToggle>
      <app-material-icon>
        view_column
      </app-material-icon>
      {{t('columns')}}
    </button>
    <div ngbDropdownMenu aria-labelledby="columns-dropdown">
      <ng-container>
        <input class="form-control m-3 p-2" type="text" placeholder="{{t('search_column')}}" style="width: 200px"
          (keyup)="onSearchColumns($event)" [(ngModel)]="searchColumns">
        <ng-container *ngFor="let column of _columns; let i = index;">
          <button ngbDropdownItem (click)="onClickColumns(i)" class="m-2 p-2" *ngIf="column.display">
            <div class="form-check ms-1">
              <input class="form-check-input" role="button" type="checkbox" [(ngModel)]="column.selected"
                [disabled]="column.default">
              <label class="form-check-label" role="button">
                {{ t(column.name) }}
              </label>
            </div>
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="vr d-none d-lg-block" *ngIf="objectFilter && _columns.length"></div>

  <!-- filters -->
  <div ngbDropdown *ngIf="objectFilter" [hidden]="objectFilter.filters.length === objectFilter.currentFilters.length">
    <button class="btn btn-sm btn-gray" id="filter-dropdown" ngbDropdownToggle>
      <app-material-icon>
        add
      </app-material-icon>
      {{t('add_filter')}}
    </button>
    <div ngbDropdownMenu aria-labelledby="filter-dropdown">
      <ng-container *ngFor="let filter of objectFilter.filters; let i = index">
        <ng-container *ngIf="filter && !filter.selected">
          <button (click)="onSelect(i)" ngbDropdownItem class="m-2 p-2">
            {{ t(filter.name) }}
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div id="current-filters" *ngIf="objectFilter">
    <div class="d-flex flex-wrap gap-2">
      <ng-container *ngFor="let filter of objectFilter.currentFilters; let i = index;">
        <ng-container *ngIf="filter.selected && (filter.visible === undefined || filter.visible === true)">

          <!-- STRING -->
          <div class="filter" *ngIf="filter.type === FilterEnum.STRING">
            <ng-template #popStringContent>
              <div>
                <input [(ngModel)]="filter.value" type="text" class="form-control" [name]="filter.id"
                  name="{{filter.id}}" [placeholder]="t(filter.name)" (keyup)="onKeyUp($event, stringPopover)">
                <button class="btn btn-outline-light text-danger m-1 ms-auto" type="button"
                  (click)="onRemoveVisibleFilter(i)" *ngIf="!filter.isNotRemovable">{{t('remove')}}</button>
              </div>
            </ng-template>
            <div class="btn btn-sm btn-gray">
              <span role="button" [ngbPopover]="popStringContent" #stringPopover="ngbPopover" [autoClose]="'outside'"
                [popperOptions]="popperOptions" (hidden)="onHidden(i)" placement="bottom" [id]="filter.id">
                <span class="fw-bold">{{ t(filter.name) }}</span>
                <span *ngIf="filter.value">: {{ filter.value }}</span>
              </span>
              <app-material-icon *ngIf="filter.value && !filter.isNotRemovable" class="text-dark" role="button"
                (click)="onRemoveNotVisibleFilter(i)">
                close
              </app-material-icon>
              <app-material-icon *ngIf="!filter.value" class="text-dark" role="button" (click)="stringPopover.toggle()">
                expand_more
              </app-material-icon>
            </div>
          </div>

          <!-- DATE -->
          <div *ngIf="filter.type === FilterEnum.DATE">
            <ng-template #popDateContent>
              <div>
                <div class="input-group">
                  <input class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #d="ngbDatepicker"
                    [minDate]="getMinDate(filter.minDate)" [maxDate]="getMaxDate(filter.maxDate)"
                    [(ngModel)]="filter.value" (keyup)="onKeyUp($event, datePopover)">
                  <div class="input-group-append">
                    <button class="btn text-primary btn-sm d-inline-flex type-toggle" (click)="d.toggle()"
                      type="button">
                      <app-material-icon>
                        calendar_month
                      </app-material-icon>
                    </button>
                  </div>
                </div>
                <button class="btn btn-outline-light text-danger m-1 ms-auto" type="button"
                  (click)="onRemoveVisibleFilter(i)" *ngIf="!filter.isNotRemovable">{{t('remove')}}</button>
              </div>
            </ng-template>
            <div class="btn btn-sm btn-gray">
              <span role="button" [ngbPopover]="popDateContent" #datePopover="ngbPopover" [autoClose]="'outside'"
                [popperOptions]="popperOptions" (hidden)="onHidden(i)" placement="bottom" [id]="filter.id">
                <span class="fw-bold">{{ t(filter.name) }}</span>
                <span *ngIf="filter.value">: {{ filterService.validateDateInput(filter) }}</span>
              </span>
              <app-material-icon *ngIf="filter.value && !filter.isNotRemovable" class="text-dark" role="button"
                (click)="onRemoveNotVisibleFilter(i)">
                close
              </app-material-icon>
              <app-material-icon *ngIf="!filter.value" class="text-dark" role="button" (click)="datePopover.toggle()">
                expand_more
              </app-material-icon>
            </div>
          </div>

          <!-- LIST -->
          <div *ngIf="filter.type === FilterEnum.LIST">
            <ng-template #popListContent>
              <div class="my-popover">
                <ng-select [items]="filter.values" [clearable]="false" bindLabel="description"
                  [placeholder]="t(filter.name)" [name]="filter.id" [(ngModel)]="filter.selectedValue"
                  (ngModelChange)="onNgModelChange(listPopover,filter.closeOnSelect)" groupBy="groupByValue">
                  <ng-template ng-label-tmp let-item="item">
                    <span>{{t(item.description)}}</span>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <span>{{t(item.description)}}</span>
                  </ng-template>
                </ng-select>
                <button class="btn btn-outline-light text-danger m-1 ms-auto" type="button"
                  (click)="onRemoveVisibleFilter(i)" *ngIf="!filter.isNotRemovable">{{t('remove')}}</button>
              </div>
            </ng-template>
            <div class="btn btn-sm btn-gray">
              <span role="button" [ngbPopover]="popListContent" #listPopover="ngbPopover" [autoClose]="'outside'"
                [popperOptions]="popperOptions" (hidden)="onHidden(i)" placement="bottom" [id]="filter.id">
                <span class="fw-bold">{{ t(filter.name) }}</span>
                <span *ngIf="filter.selectedValue">: {{ t(filter.selectedValue.description) }}</span>
              </span>
              <app-material-icon *ngIf="filter.selectedValue && !filter.isNotRemovable" class="text-dark" role="button"
                (click)="onRemoveNotVisibleFilter(i)" [id]="'close-'+filter.id">
                close
              </app-material-icon>
              <app-material-icon *ngIf="!filter.selectedValue" class="text-dark" role="button"
                (click)="listPopover.toggle()">
                expand_more
              </app-material-icon>
            </div>
          </div>

          <!-- NUMBER -->
          <div *ngIf="filter.type === FilterEnum.NUMBER">
            <ng-template #popNumberContent>
              <div>
                <input [(ngModel)]="filter.value" type="number" class="form-control" [id]="filter.id" [name]="filter.id"
                  [placeholder]="t(filter.name)" (keyup)="onKeyUp($event, numberPopover)">
                <button class="btn btn-outline-light text-danger m-1 ms-auto" type="button"
                  (click)="onRemoveVisibleFilter(i)" *ngIf="!filter.isNotRemovable">{{t('remove')}}</button>
              </div>
            </ng-template>
            <div class="btn btn-sm btn-gray">
              <span role="button" [ngbPopover]="popNumberContent" #numberPopover="ngbPopover" [autoClose]="'outside'"
                [popperOptions]="popperOptions" (hidden)="onHidden(i)" placement="bottom" [id]="filter.id">
                <span class="fw-bold">{{ t(filter.name) }}</span>
                <span *ngIf="filter.value != undefined">: {{ filter.value }}</span>
              </span>
              <app-material-icon *ngIf="filter.value && !filter.isNotRemovable" class="text-dark" role="button"
                (click)="onRemoveNotVisibleFilter(i)">
                close
              </app-material-icon>
              <app-material-icon *ngIf="!filter.value" class="text-dark" role="button" (click)="numberPopover.toggle()">
                expand_more
              </app-material-icon>
            </div>
          </div>

          <!-- MULTIPLE-LIST -->
          <div *ngIf="filter.type == FilterEnum.MULTIPLE_LIST">
            <ng-template #popMultipleContent>
              <div>
                <ng-select [items]="filter.values" [multiple]="true" [closeOnSelect]="false" [hideSelected]="true"
                  bindLabel="description" [placeholder]="t(filter.name)" [name]="filter.id"
                  [(ngModel)]="filter.selectedValues">
                </ng-select>
                <button class="btn btn-outline-light text-danger m-1 ms-auto" type="button"
                  (click)="onRemoveVisibleFilter(i)" *ngIf="!filter.isNotRemovable">{{t('remove')}}</button>
              </div>
            </ng-template>
            <div class="btn btn-sm btn-gray">
              <span role="button" [ngbPopover]="popMultipleContent" #numberPopover="ngbPopover" [autoClose]="'outside'"
                [popperOptions]="popperOptions" (hidden)="onHidden(i)" placement="bottom" [id]="filter.id">
                <span class="fw-bold">{{ t(filter.name) }}</span>
                <span *ngIf="filter.selectedValues">{{ filterService.validateMultipleInput(filter.selectedValues)
                  }}</span>
              </span>
              <app-material-icon *ngIf="filter.selectedValues && !filter.isNotRemovable" class="text-dark" role="button"
                (click)="onRemoveNotVisibleFilter(i)">
                close
              </app-material-icon>
              <app-material-icon *ngIf="!filter.selectedValues" class="text-dark" role="button"
                (click)="numberPopover.toggle()">
                expand_more
              </app-material-icon>
            </div>
          </div>

        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
