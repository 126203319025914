import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ModalComponent } from 'src/app/app-commons/components/modal/modal.component';
import { ChannelResource, ClassResource, SubtypeResource } from 'src/app/data/models';
import { InputConfig, SelectValue, getInputConfig, setInputConfig } from 'src/app/data/others/input-config.interface';
import { ObjectValidatorService } from 'src/app/services/others/object-validator/object-validator.service';
import { ToasterService } from 'src/app/services/others/toaster/toaster.service';
import { ColumnTypeEnum } from 'src/global/column-type-enum';
import { ToasterEnum } from 'src/global/toaster-enum';
import { FilterSearch } from '../../search-filter/filter-util';
import { FilterClasses } from './classes-configs';


@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent extends ModalComponent {
  // Define un arreglo para almacenar los valores seleccionados de los select
  selectValues: SelectValue = {};
  channels: ChannelResource[] | undefined = [];

  /**
     * modal title
     */
  @Input() title = '';

  /**
   * question for the user, if it is sent empty it will not be displayed
   */
  @Input() bodyQuestion = 'txt_confirm_really_want_remove';

  /**
   * complementary text for the user, if it is sent empty it will not be displayed
   */
  @Input() bodyTxt = 'txt_confirm_no_recovery';

  /**
   * Type of button to be displayed to confirm the action
   */

  /**
   * confirmation button text
   */
  @Input() btnTxt = 'txt_save';

  /**
   * input configuration
   */
  configuracionInputs: InputConfig[] = [];

  /**
   *
   *  classes
   */
  classes: ClassResource[] = [];

  /**
   *
   *  subtypes
   */
  subtypes: SubtypeResource[] = [];

  /**
   * method to be called if confirmed
   */
  @Output() actionConfirm = new EventEmitter();

  /**
   * method to be called if canceled
   */
  @Output() actionCancel = new EventEmitter();

  filterClasses: FilterSearch = FilterClasses;
  loading = false;
  active = 1;
  columnTypeEnum = ColumnTypeEnum;
  currentObject: any;
  isCreation = false;
  flagNegativeNumber = false;

  constructor(
    modalService: NgbModal,
    private toasterService: ToasterService,
    private translocoService: TranslocoService,
    private objectValidatorService: ObjectValidatorService
  ) {
    super(modalService)
  }

  returnValue!: any;

  /**
   * @param returnValue
   * value that is returned when confirming the modal
   */
  openModal(returnValue: any, configuracionInputs: InputConfig[] | undefined, classes: ClassResource[], subtypes: SubtypeResource[]) {
    this.resetAll();
    this.classes = classes;
    this.subtypes = subtypes;
    this.returnValue = returnValue;
    if (configuracionInputs !== undefined) {
      this.configuracionInputs = configuracionInputs;
      this.loadPreselectorValues(this.configuracionInputs);
    }
    this.open();
  }

  resetAll() {
    this.configuracionInputs = [];
    this.selectValues = {};
    this.classes = [];
    this.subtypes = [];
    this.active = 1;
    this.currentObject = undefined;
  }

  //The preset values for the select type inputs are loaded.
  loadPreselectorValues(configuracionInputs: InputConfig[]) {
    for (let input of configuracionInputs) {
      if (input.inputType === 'object') {
        this.loadPreselectorValues(input.value);
        continue;
      }

      if (input.inputType === 'select' && input.fieldName) {
        if (input.fieldName === 'class' && input.selectOptions) {
          input.selectOptions.items = this.classes;
        } else if (input.fieldName === 'subtipo' && input.selectOptions) {
          input.selectOptions.items = this.subtypes;
        }

        if (!this.selectValues[input.alias ? input.alias : input.fieldName]) {
          if (input.value === '') {
            this.selectValues[input.alias ? input.alias : input.fieldName] = input.selectOptions?.selectedItem;
          } else {
            if (input.fieldName === 'channel') {
              const foundIdChannel = input.selectOptions && input.selectOptions.items.find((item: any) => item.name === input.value)?.id;
              this.selectValues[input.alias ? input.alias : input.fieldName] = foundIdChannel;
            } else if (input.fieldName === 'class') {
              const foundIdClass = input.selectOptions && input.selectOptions.items.find((item: any) => item.name === input.value)?.clase; //atributo clase tiene el id
              this.selectValues[input.alias ? input.alias : input.fieldName] = foundIdClass;
            } else if (input.fieldName === 'subtipo') {
              const foundIdSubtype = input.selectOptions && input.selectOptions.items.find((item: any) => item.subtype_description === input.value)?.subtype; //atributo clase tiene el id
              this.selectValues[input.alias ? input.alias : input.fieldName] = foundIdSubtype;
            } else {
              this.selectValues[input.alias ? input.alias : input.fieldName] = input.value;
            }
          }
        }
      }
    }
  }

  updateInputs(configurationInputs: InputConfig[]) {
    for (let input of configurationInputs) {
      if (input.inputType === 'object') {
        this.updateInputs(input.value);
        continue;
      }
      if (input.inputType === 'select' && input.fieldName) {
        if (input.fieldName === 'class') {
          let position = this.selectValues[input.alias ? input.alias : input.fieldName] as number - 1;//-1;
          let value = input.selectOptions?.items[position]?.name;
          input.value = value;
          if (input.selectOptions) {
            this.selectValues['class_id'] = position;
            input.selectOptions.selectedItem = position;
          }
        } else if (input.fieldName === 'status') {
          let value = this.selectValues[input.alias ? input.alias : input.fieldName];
          input.value = value;
        } else if (input.fieldName === 'subtipo') {
          let subtype_id = this.selectValues[input.alias ? input.alias : input.fieldName];
          let value = this.subtypes.find((item: any) => item.subtype === subtype_id)?.subtype_description;
          if (input.selectOptions) {
            input.selectOptions.selectedItem = subtype_id;
          }
          input.value = value;
        } else {
          input.value = this.selectValues[input.alias ? input.alias : input.fieldName];
        }
      }
    }
  }


  confirmBtn() {
    this.updateInputs(this.configuracionInputs);
    if (this.isValid(this.configuracionInputs)) { //Si retorna false, es porque no se cumplieron las condiciones, y se niega el cierre del modal
      this.selectValues = {};
      this.actionConfirm.emit(this.configuracionInputs);
      this.close();
    } else if (this.flagNegativeNumber) {
      this.flagNegativeNumber = false;
      this.toasterService.show({ type: ToasterEnum.ERROR, message: "msg_validation_number_negative_field" });
    } else {
      this.toasterService.show({ type: ToasterEnum.ERROR, message: "msg_error_complete_all_fields" });
    }
  }

  closeBtn() {
    this.actionCancel.emit();
    this.close();
  }

  //*Validate for input type date number
  validateNumber(input: any): boolean {
    let flag = false;

    if (input.value < 0) {
      flag = true;
    }

    if (input.value === null) {
      flag = false;
    }
    return flag;
  }

  isInvalid(input: any): boolean {
    if (input.required) {
      if (input.value === null || input.value === '' || input.value === undefined) {
        return true;
      }
    }
    if (input.value < 0) {
      return true;
    }
    return false;
  }

  isNegative(input: any) {
    if (input.value < 0) {
      this.toasterService.show({ type: ToasterEnum.ERROR, message: "msg_validation_number_negative_field" });
    }
  }

  isValid(configurationInputs: InputConfig[]): boolean {
    for (const input of configurationInputs) {
      if (input.inputType === 'object') {
        if (!this.isValid(input.value)) {
          return false;
        }
        continue;
      }
      if (input.inputType === 'list') {
        for (const value of input.value) {
          let list = getInputConfig(value, input.objectConfig ?? []);
          if (!this.isValid(list)) {
            return false;
          }
        }
        continue;
      }
      if (input.required) {
        switch (input.inputType) {
          case 'text':
          case 'date':
          case 'textarea':
            if (input.value === '' || input.value === undefined || input.value === null) {
              return false; // Devolvemos falso si el valor está vacío
            }
            break;
          case 'number':
            if (input.value === undefined || input.value === null || input.value < 0) {
              this.flagNegativeNumber = input.value < 0 ? true : false;
              return false; // Devolvemos falso si el valor es menor que 0
            }
            break;
          case 'select':
            if (input.required) {
              if (input.fieldName === 'channel' || input.fieldName === 'class') {
                let selectValue = this.selectValues[input.alias ? input.alias : input.fieldName];
                if (selectValue === undefined || selectValue === null) {
                  return false;
                }
              } else {
                if (input.value === '' || input.value === undefined || input.value === null) {
                  return false;
                }
              }
            }
            break;
        }
      } else {
        if (input.inputType === 'number') {
          if (input.value < 0) {
            this.flagNegativeNumber = true;
            return false; // Devolvemos falso si el valor es menor que 0
          }
        }
      }
    }
    return true; // Si no se encontró ninguna condición falsa, devolvemos verdadero
  }

  onSelectChange(input: InputConfig, event: any) {
    if (input?.selectOptions && typeof input.fieldName === 'string') {
      this.selectValues[input.alias ? input.alias : input.fieldName] = event;
    }
  }

  isSelectInvalid(input: InputConfig): boolean {
    if (input?.selectOptions && typeof input.fieldName === 'string') {
      return this.selectValues[input.alias ? input.alias : input.fieldName] === undefined && input.required;
    }
    return false;
  }

  existObjectConfig() {
    return this.configuracionInputs.find(input => input.inputType === 'object') !== undefined;
  }

  getObjectsConfigExist(): InputConfig[] {
    return this.configuracionInputs.filter(input => input.inputType === 'object')[0].value || [];
  }

  doubleHandler(objectData: any, modal: any, inputConfig: InputConfig[]) {
    this.currentObject = objectData;
    inputConfig = this.loadDynamicData(inputConfig);
    let inputConfigs = getInputConfig(objectData, inputConfig);
    modal.openModal(undefined, inputConfigs, this.classes, this.subtypes);
  }

  loadDynamicData(inputConfigs: InputConfig[]): InputConfig[] {
    let channelSelected = Number(localStorage.getItem('channelSelected'));
    let channel_name = localStorage.getItem('channel_name');
    for (const input of inputConfigs) {
      if (input.inputType === 'select' && input.fieldName === 'channel') {
        if (input.selectOptions) {
          input.selectOptions.items = this.channels || [];
          for (const channel of this.channels || []) {
            if (channel.id === channelSelected && input.selectOptions) {
              input.selectOptions.selectedItem = channel.id;
              input.value = this.channels?.find((item: ChannelResource) => item.id === channelSelected)?.name;
              break;
            }
          }
        }
      }
      if (input.inputType === 'select' && input.fieldName === 'class' && channel_name !== '') {
        try {
          const data: any = this.classes;
          if (input.selectOptions) {
            input.selectOptions.items = data.items;
          }
        } catch (error) {
          this.toasterService.show({ message: "msg_channel_error_list", header: "error", type: ToasterEnum.ERROR });
        }
      }
    }

    return inputConfigs;
  }

  removeItem(arrayDeleteItem: any[]) {
    let list: any = arrayDeleteItem[0], position: number = arrayDeleteItem[1];
    list.splice(position, 1);
  }

  getCellText(data: any, columnType: ColumnTypeEnum) {
    if (data === undefined || data === null || data === '') {
      return '-';
    }

    if (columnType === ColumnTypeEnum.STATUS) {
      return this.getStatusText(data);
    } else if (columnType === ColumnTypeEnum.YN) {
      return this.getYorNText(data);
    } else if (columnType === ColumnTypeEnum.NO_CIA) {
      let country_name = localStorage.getItem('countryName');
      return data + ' - ' + country_name;
    }
    return data;
  }

  private getStatusText(status: string) {
    if (!status) {
      return '-';
    }
    switch (status.toLocaleLowerCase()) {
      case 'a':
        return this.translocoService.translate('txt_active');
      case 'i':
        return this.translocoService.translate('txt_inactive');
      case 'p':
        return this.translocoService.translate('txt_pending');
      case 'n':
        return this.translocoService.translate('txt_not_available');
      default:
        return '-';
    }
  }

  private getYorNText(value: string) {
    if (!value) {
      return '-';
    }
    switch (value.toLocaleLowerCase()) {
      case 's':
        return this.translocoService.translate('txt_yes');
      case 'n':
        return this.translocoService.translate('txt_no');
      default:
        return '-';
    }
  }

  closeModal() {
    this.isCreation = false;
    this.currentObject = undefined;
  }

  getObjectsConfig(configurationInputs: InputConfig[]): InputConfig {
    return configurationInputs.filter(input => input.inputType === 'object')[0] || undefined;
  }


  configsFinal(data: any, currentObjectParam: any = undefined) {
    let objectConfig = this.getObjectsConfig(data);
    if (objectConfig) {
      setInputConfig(this.currentObject[objectConfig.fieldName + ''], objectConfig.value);
    }
    setInputConfig(currentObjectParam ? currentObjectParam : this.currentObject, data);

    let syncroForSelected = localStorage.getItem('syncroForSelected');
    if (syncroForSelected === 'DELIVERY') {
      let class_id = data.find((inputConfig: InputConfig) => inputConfig.fieldName === 'class')?.selectOptions?.selectedItem;
      if (currentObjectParam) {
        currentObjectParam.class_id = class_id + 1;
      } else {
        this.currentObject.class_id = class_id + 1;
      }
    } else if (syncroForSelected === 'SALES') {
      let subtype = data.find((inputConfig: InputConfig) => inputConfig.fieldName === 'subtipo');
      if (subtype) {
        let subtype_id = subtype?.selectOptions?.selectedItem;
        if (currentObjectParam) {
          currentObjectParam.subtype = subtype_id;
        } else {
          this.currentObject.subtype = subtype_id;
        }
      }
    }
    if (currentObjectParam) {
      return;
    }
    if (this.isCreation) {
      //pendiente
    }
    this.currentObject = undefined;
  }

  isInfoIncomplete(currentConfig: any, currentData: any, validateSelectedField: boolean = false) {
    if (validateSelectedField && !currentData.selected) {
      return false;
    }
    return this.objectValidatorService.isInfoIncomplete(currentConfig, currentData);
  }

  isCellIncomplete(fieldName: string, currentConfig: any, currentData: any, validateSelectedField: boolean = false) {
    if (validateSelectedField && !currentData.selected) {
      return false;
    }
    return this.objectValidatorService.isCellIncomplete(fieldName, currentConfig, currentData);
  }

  isNotNullOrUndefined(data: any) {
    if (data === undefined || data === null) {
      return true;
    }
    if (data === '') {
      return true;
    }
    if (data < 0) {
      return true;
    }
    return false;
  }

}

