import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Component to display empty messages, generally used in lists to indicate that there are no items.
 *
 * @example easy use
 * <app-empty-panel></app-empty-panel>
 *
 * @example use in lists
 * <app-empty-panel emptyMessage="txt_empty" icon="inventory_2" [emptyPanelStyle]="false"
 * (addItem)="addItem()"></app-empty-panel>
 */
@Component({
  selector: 'app-empty-panel',
  templateUrl: './empty-panel.component.html',
  styleUrls: ['./empty-panel.component.scss']
})
export class EmptyPanelComponent {
  /**
   * Input that validates whether or not the panel style is added,
   * if not added it is white.
   * Default is true.
   */
  @Input() emptyPanelStyle: boolean = true;

  /**
   * Message to be displayed to indicate that there are no items.
   * Default is txt_default_empty
   */
  @Input() emptyMessage: string = 'txt_default_empty';

  /**
   * Custom padding to enlarge the content (css)
   * Default is undefined
   *
   * @example
   * 4rem
   */
  @Input() customPadding: string | undefined = undefined;

  /**
   * Google Fonts icon to display on top of text.
   * Default is undefined
   */
  @Input() icon: string | undefined = undefined;

  /**
   * Output that is emitted when the button is pressed, returns no value.
   * If the output is not sent, the button is not shown.
   */
  @Output() addItem = new EventEmitter();

  /**
   * Button text.
   * Default is txt_add_row
   */
  @Input() btnTxt: string = 'txt_add_row';

  @Input() showBtn: boolean = true;

  isAddItemUsed: boolean = false;

  ngOnInit(): void {
    this.isAddItemUsed = this.addItem.observed;
  }

  add() { this.addItem.emit(); }

}
