import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CognitoService } from '../services/cognito/cognito.service';
import { Router } from '@angular/router';

@Injectable()
export class SecureInterceptor implements HttpInterceptor {

  constructor(
    private cognitoService: CognitoService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.cognitoService.getAccessToken();
    const token = localStorage.getItem('token');
    
    const headers = request.headers.set('Authorization', `Bearer ${token}`);
    const secureRequest = request.clone({ headers });
    return next.handle(secureRequest);
  }
}