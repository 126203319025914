<ng-template #content let-modal *transloco="let t">

    <div class="myCustomAnimation ">
        <div class="modal-header">
            <h5 class="modal-title">{{t(modalTitle)}}</h5>
            <button (click)="cancel()" class="close btn  btn-gray">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ng-content></ng-content>
        </div>
        <div *ngIf="showFooter" class="modal-footer  d-flex justify-content-between">
            <button (click)="cancel()" *ngIf="showCancelButton" class="btn btn-outline-primary">{{t('cancel')}}</button>
            <button (click)="accept()" *ngIf="showAcceptButton" class="btn btn-primary">{{t(textAccept)}}</button>
            <button (click)="customButton()" *ngIf="showCustomButton" class="btn btn-info">{{t(textCustomButton)}}</button>
        </div>
    </div>

</ng-template>
