<div class="col-12 col-lg-12" *transloco="let t">
  <input type="file" id="fileInput" hidden (change)="onFileChange($event)" />
  <label
    for="fileInput"
    class="upload-label"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    [attr.accept]="allowedExtensions.join(', ')"
    (drop)="onDrop($event)"
    [ngClass]="{'error': hasError}"
  >
    <div class="upload-content">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="57"
        viewBox="0 0 56 57"
        fill="none"
      >
        <path
          d="M45.15 23.9263C43.5633 15.8763 36.4933 9.83301 28 9.83301C21.2567 9.83301 15.4 13.6597 12.4833 19.2597C5.46 20.0063 0 25.9563 0 33.1663C0 40.8897 6.27667 47.1663 14 47.1663H44.3333C50.7733 47.1663 56 41.9397 56 35.4997C56 29.3397 51.2167 24.3463 45.15 23.9263ZM32.6667 30.833V40.1663H23.3333V30.833H16.3333L27.1833 19.983C27.65 19.5163 28.3733 19.5163 28.84 19.983L39.6667 30.833H32.6667Z"
          fill="#BABBBD"
        />
      </svg>
      <span>{{ t(textLineOne) }}</span>
      <span>{{ t(textLineTwo) }}</span>
      <div class="d-flex align-items-center">
        <hr class="flex-grow-1 mx-2" />
        <span>o</span>
        <hr class="flex-grow-1 mx-2" />
      </div>
      <button class="btn-find-file" (click)="onButtonClick()">{{ t("txt_find_file") }}</button>
      <div *ngIf="fileName" class="file-name">
        <i class="fa-solid fa-file"></i>{{ fileName }}
      </div>
      
      <div *ngIf="showTemplate">
        <button class="btn " (click)="downloadTemplate()"> <i class="fa-solid fa-file"></i>{{ t('txt_template_download') }}
        </button>
        
      </div>

    </div>
  </label>
</div>
