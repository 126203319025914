import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingControlService {

  loading = false;
  forceLoading = false;

  showLoadingIndicator(): void {
    this.loading = true;
  }

  hideLoadingIndicator(): void {
    if (this.forceLoading) return;
    this.loading = false;
  }

  forceShowLoadingIndicator(): void {
    this.forceLoading = true;
  }

  forceHideLoadingIndicator(): void {
    this.forceLoading = false;
    this.hideLoadingIndicator();
  }
}
