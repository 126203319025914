<div class="header-container header px-lg-5" *transloco="let t">
  <div
    class="title-container d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between pt-4"
  >
    <div class="title">
      <div class="logo-container d-flex align-items-center">
        <button
          class="back-btn p-0 btn btn-text-black"
          (click)="return()"
          *ngIf="showBackButton()"
        >
          <app-material-icon>arrow_back</app-material-icon>
        </button>
        <h1 class="h4 fw-bold">
          {{ t(title) }}
        </h1>
      </div>
      <ng-container *ngIf="showBreadCrumbs">
        <xng-breadcrumb>
          <ng-container *xngBreadcrumbItem="let breadcrumb">
            <ng-container *ngIf="currentLanguage === 'es' && breadcrumb === 'view'; else otherText">
              Ver
            </ng-container>
            <ng-template #otherText>{{ t(breadcrumb) }} </ng-template>
            <!-- {{" " }} {{ breadcrumb }} -->
          </ng-container>
        </xng-breadcrumb>
      </ng-container>
    </div>
    <div
      *ngIf="type !== headerEnum.NONE"
      class="actions d-flex flex-column flex-lg-row gap-1"
    >
      <button
        *ngIf="type == headerEnum.LIST && createRoute"
        class="btn btn-primary w-100 w-lg-auto"
        (click)="createRouteAction($event)"
      >
        {{ t(createText) }}
        <app-material-icon>add</app-material-icon>
      </button>

      <button
        *ngIf="type == headerEnum.LIST && isCreateAction"
        (click)="create()"
        class="btn btn-primary w-100 w-lg-auto"
      >
        {{ t("create") }}
        <app-material-icon>add</app-material-icon>
      </button>

      <button
        *ngIf="showDeleteButton"
        (click)="delete()"
        class="btn btn-text-danger w-100 w-lg-auto"
      >
        {{ t("delete") }}
      </button>

      <button
        *ngIf="showEditButton && showEditButtonInput"
        [routerLink]="editRoute"
        class="btn btn-outline-primary w-100 w-lg-auto"
      >
        {{ t("edit") }}
        <app-material-icon>edit</app-material-icon>
      </button>

      <button
        *ngIf="type == headerEnum.SAVE && cancelRoute"
        [routerLink]="cancelRoute"
        class="btn btn-outline-primary w-100 w-lg-auto"
      >
        {{ t("cancel") }}
      </button>

      <button
        *ngIf="type == headerEnum.SAVE && saveAction.observers.length"
        (click)="save()"
        class="btn btn-primary w-100 w-lg-auto"
      >
        {{ t("save") }}
        <app-material-icon>done</app-material-icon>
      </button>

      <button
        *ngIf="type == headerEnum.VIEWDELETE"
        [routerLink]="cancelRoute"
        class="btn btn-outline-primary w-100 w-lg-auto"
      >
        {{ t("return") }}
      </button>

      <button
        *ngIf="type == headerEnum.OPEN || type == headerEnum.VIEW_OPEN"
        (click)="open()"
        class="btn btn-primary w-100 w-lg-auto"
      >
        {{ t("txt_open") }}
      </button>
      <button
        *ngIf="type == headerEnum.CLOSE || type == headerEnum.VIEW_CLOSE"
        (click)="close()"
        class="btn btn-danger w-100 w-lg-auto"
      >
        {{ t("txt_close") }}
      </button>
    </div>
  </div>
  <div
    #navigationTab
    class="row flex-nowrap gap-3 pt-3 navigation-tabs position-relative pb-4"
    (window:resize)="checkOverflow()"
    (scroll)="checkOverflow()"
  >
    <div
      [hidden]="!scrollLeft"
      class="scroll-icon cursor-pointer position-sticky start-0 bottom-0 bg-body"
      (click)="scrollingLeft()"
    >
      <app-material-icon>keyboard_arrow_left</app-material-icon>
    </div>
    <ng-container *ngIf="!edit">
      <div
      *ngFor="let tab of tabs; let i = index"
      (click)="selectTab(i)"
      [ngClass]="{
        noClick: noNavigation,
        'text-primary-400': tab.complete !== undefined && tab.complete,
        'text-primary-600': tab.view,
        'text-gray-600':
          !tab.view && (tab.complete !== undefined ? !tab.complete : true),
        'cursor-pointer': !validateCompleteTab,
        'w-75': isOverflow
      }"
      class="text-decoration-none w-auto overflow-hidden text-nowrap"
    >
      <div
        class="numberCircle"
        [ngClass]="{
          noClick: noNavigation,
          numberCircleCompleted: tab.complete !== undefined && tab.complete,
          numberCircleSelected: tab.view,
          numberCircleNormal:
            !tab.view && (tab.complete !== undefined ? !tab.complete : true)
        }"
      >
      <ng-container *ngIf="showTabCheck || showTabNumbers">
        <div *ngIf="(tab.complete === undefined || !tab.complete) || !showTabCheck && tab.complete">
          <span>{{ i + 1 }}</span>
        </div>
        <div *ngIf="(tab.complete !== undefined && tab.complete) && showTabCheck">
          <app-material-icon>check</app-material-icon>
        </div>
      </ng-container>
      </div>
      {{ t(tab.name) }}
    </div>
    </ng-container>

    <div
      [hidden]="!scrollRight"
      class="scroll-icon position-sticky end-0 bottom-0 cursor-pointer bg-body"
      (click)="scrollingRight()"
    >
      <app-material-icon>keyboard_arrow_right</app-material-icon>
    </div>
  </div>
</div>
